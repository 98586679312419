import { useState, useEffect } from 'react'
import { Maybe } from 'typ'
import { log } from 'logger'

const getIdElement = (id: string, document: Maybe<Document>) => {
  if (!document || !id) return null
  return document?.getElementById(id)
}

export const usePortal = (
  document: Maybe<Document>,
  renderDiv = true,
  id = ''
): Maybe<HTMLDivElement> => {
  const [portal, setPortal] = useState(null as Maybe<HTMLDivElement>)
  useEffect(() => {
    if (renderDiv && !portal) {
      const idEl = getIdElement(id, document)
      const root = idEl ?? document?.createElement('div')
      if (root && !idEl && id) {
        root.id = id
      }
      setPortal(root as HTMLDivElement)
    }
    if (!renderDiv && portal) {
      setPortal(null)
    }
  }, [setPortal, document, portal, renderDiv, id])

  useEffect(() => {
    const idEl = getIdElement(id, document)
    if (!portal || idEl) return undefined
    try {
      document?.body.appendChild(portal)
    } catch (error) {
      log.error(
        'Something went wrong when adding a portal to the document body'
      )
      log.error(error)
    }
    return () => {
      try {
        document?.body.removeChild(portal)
      } catch (error) {
        log.warn(
          'Something went wrong removing a portal from the document body'
        )
        log.warn(error)
      }
    }
  }, [portal, document, id])
  return portal
}
