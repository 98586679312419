/* eslint-disable sonarjs/cognitive-complexity */
/** @jsxImportSource theme-ui */
import { useHasMounted, usePortal } from 'hooks'
import { FC, MouseEvent, PropsWithChildren, ReactNode, useRef } from 'react'
import ReactDOM from 'react-dom'
import { FocusOn } from 'react-focus-on'
import { StyleObject, TestProps } from 'typ'
import { isDefined, isFocusable } from 'typeguards'
import { useDelayedRemoval } from '../../hooks'
import { Box } from '../../layout'
import { ModalOverlay } from './ModalOverlay'

export interface ModalProps extends TestProps {
  children: ReactNode
  // The relative index value for the modal zIndex in the stack of modals
  index?: 0 | 1
  isClosingDisabled?: boolean
  isOpen: boolean
  onClickOutside?: VoidFunction
  onClose: VoidFunction
  onEscapeKey?: VoidFunction
  overlayStyles?: StyleObject
  wrapperStyles?: StyleObject
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  index = 0,
  isClosingDisabled,
  isOpen,
  onClickOutside,
  onClose,
  onEscapeKey,
  wrapperStyles,
  ...props
}) => {
  const { isDisplayed } = useDelayedRemoval(isOpen)
  const hasMounted = useHasMounted()
  const portal = usePortal(typeof document !== 'undefined' ? document : null, isDisplayed)
  const handleModalClick = (e: MouseEvent) => e.stopPropagation()
  const isReady = portal && hasMounted
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    if (!isClosingDisabled) onClose()
  }

  const handleClickOutside = () => {
    if (onClickOutside) onClickOutside()
    else handleClose()
  }

  const handleEscapeKey = () => {
    if (!isDefined(onEscapeKey)) {
      handleClose()
      return
    }
    if (!isFocusable(wrapperRef.current)) return
    if (document.activeElement === wrapperRef.current) {
      onEscapeKey()
    } else {
      wrapperRef.current.focus()
    }
  }

  if (!isReady || (!isOpen && !isDisplayed)) return null
  return ReactDOM.createPortal(
    <>
      <ModalOverlay isOpen={isOpen} style={{ zIndex: `modal-overlay-${index}` }} />
      {isOpen && (
        <Box
          data-testid="modal-container"
          style={{
            position: 'fixed',
            inset: '0px',
            display: 'grid',
            placeItems: 'center',
            padding: 3,
            zIndex: `modal-${index}`,
            pointerEvents: 'auto',
            ...wrapperStyles,
          }}
        >
          <FocusOn onClickOutside={handleClickOutside} onEscapeKey={handleEscapeKey}>
            <Box
              data-testid={props['data-testid'] ?? 'modal'}
              data-cy={props['data-cy']}
              role="dialog"
              style={{
                zIndex: `modal-${index}`,
                visibility: isOpen ? 'visible' : 'hidden',
                pointerEvents: isOpen ? 'auto' : 'none',
              }}
            >
              <Box
                data-testid="modal-focus-wrapper"
                ref={wrapperRef}
                role="presentation"
                onClick={handleModalClick}
                tabIndex={isDefined(onEscapeKey) ? -1 : undefined}
                style={{
                  '&:focus-visible': {
                    transition: 'box-shadow 0s ease-in-out .05s',
                    boxShadow: 'focusRing',
                    borderRadius: 3,
                    outline: 'none',
                  },
                }}
              >
                {children}
              </Box>
            </Box>
          </FocusOn>
        </Box>
      )}
    </>,
    portal
  )
}
