/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Box, useModal } from 'ui'
import { useHasMounted } from 'hooks'
import dynamic from 'next/dynamic'

const HamburgerMenuNav = dynamic(() =>
  import('./HamburgerMenuNav').then((mod) => mod.HamburgerMenuNav)
)
export interface HamburgerMenuToggleProps {}

export const HamburgerMenuToggle: FC<HamburgerMenuToggleProps> = () => {
  const { isOpen, openModal, closeModal, anchorRef } = useModal()
  const hasMounted = useHasMounted()
  const toggleModal = () => (isOpen ? closeModal() : openModal())
  return (
    <Box>
      <button
        aria-label="Menu Toggle"
        ref={anchorRef}
        onClick={toggleModal}
        sx={{
          position: 'relative',
          display: ['grid', 'grid', 'none'],
          placeItems: 'center',
          borderRadius: 2,
          p: 0,
          m: 0,
          bg: 'transparent',
          border: 'none',
          cursor: 'pointer',
          width: '68px',
          height: '45px',
          ':focus-visible': {
            outline: 'none',
            boxShadow: 'focusRing',
          },
          ':before, :after': {
            content: '""',
            position: 'absolute',
            width: '20px',
            height: '1px',
            bg: 'text',
            color: 'text',
            left: '24px',
          },
          ':before': {
            top: '17px',
          },
          ':after': {
            top: '28px',
          },
        }}
      />
      {hasMounted && <HamburgerMenuNav isOpen={isOpen} onClose={closeModal} />}
    </Box>
  )
}
