import { StyleObject } from 'typ'

export type CheckboxSize = 'sm' | 'md' | 'lg'

export const sizeMap: Record<CheckboxSize, number> = {
  sm: 16,
  md: 20,
  lg: 24,
}

export const translateMap: Record<CheckboxSize, string> = {
  sm: 'translate(-6.5 -6.5)',
  md: 'translate(-4.5 -4.5)',
  lg: 'translate(-2.5 -2.5)',
}

export const getBaseInputStyles = (size: CheckboxSize, disabled?: boolean) =>
  ({
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    opacity: 0,
    pointerEvents: 'auto',
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: sizeMap[size] + 1,
    width: sizeMap[size] + 1,
    minHeight: sizeMap[size] + 1,
    minWidth: sizeMap[size] + 1,
    margin: 0,
    borderRadius: 0,
    border: 'none',
    padding: 0,
  } as StyleObject)
