/** @jsxImportSource theme-ui */
import { FC, CSSProperties } from 'react'
import { useTheme } from 'theme'

interface CheckSvgProps {
  disabled?: boolean
  style?: CSSProperties
}
export const CheckSvg: FC<CheckSvgProps> = ({ disabled }) => {
  const { rawColors } = useTheme()

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-marker"
    >
      <rect
        y="0.555176"
        width="16"
        height="16"
        rx="2"
        fill={disabled ? rawColors.primaryTint3 : rawColors.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4266 4.83122C11.5108 4.74437 11.6113 4.67509 11.7224 4.62738C11.8335 4.57967 11.953 4.55448 12.0739 4.55327C12.1948 4.55206 12.3148 4.57485 12.4268 4.62033C12.5389 4.66581 12.6408 4.73307 12.7266 4.81822C12.8991 4.98976 12.9972 5.22229 12.9996 5.46555C13.0021 5.70881 12.9087 5.94326 12.7396 6.11822L7.85664 12.2552C7.77301 12.3461 7.67185 12.419 7.55924 12.4697C7.44664 12.5204 7.32494 12.5477 7.20149 12.55C7.07803 12.5524 6.95538 12.5297 6.84094 12.4833C6.72651 12.4369 6.62265 12.3678 6.53564 12.2802L3.29764 9.03022C3.17555 8.91732 3.08619 8.77355 3.03898 8.6141C2.99178 8.45464 2.98848 8.28541 3.02944 8.12423C3.0704 7.96306 3.1541 7.81593 3.2717 7.69836C3.3893 7.58079 3.53646 7.49713 3.69764 7.45622C3.85814 7.41576 4.02661 7.4196 4.1851 7.46732C4.34359 7.51505 4.48617 7.60487 4.59764 7.72722L7.15964 10.3012L11.4076 4.85922C11.4151 4.84973 11.4231 4.84071 11.4316 4.83222L11.4266 4.83122Z"
        fill="white"
      />
    </svg>
  )
}
