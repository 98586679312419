/** @jsxImportSource theme-ui */
import { FC, useMemo } from 'react'
import { Anchor, Dot, Text } from 'ui'
import useTranslation from 'next-translate/useTranslation'
import { WEBSITE_STATUS_TRACKER_URL } from 'src/assets/links'
import { useTheme } from 'theme'
import { SystemStatus } from '@/contexts/SystemStatusContext'

export interface SystemStatusIndicatorProps {
  status?: SystemStatus
}

export const d = {
  status: 'tensor-website-common:status.status',
  ok: 'tensor-website-common:status.ok',
  degraded: 'tensor-website-common:status.degraded',
  down: 'tensor-website-common:status.down',
}

export const SystemStatusIndicator: FC<SystemStatusIndicatorProps> = ({
  status,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const statusColor = useMemo(() => {
    switch (status) {
      case 'ok':
        return theme.colors.primary
      case 'degraded':
        return theme.colors.warning
      case 'down':
        return theme.colors.danger
      default:
        return theme.colors.primary
    }
  }, [status, theme])

  const statusText = useMemo(() => {
    switch (status) {
      case 'ok':
        return t(d.ok)
      case 'degraded':
        return t(d.degraded)
      case 'down':
        return t(d.down)
      default:
        return t(d.ok)
    }
  }, [status, t])

  return (
    <Anchor
      variant="unstyled"
      rel="noopener"
      target="_blank"
      href={WEBSITE_STATUS_TRACKER_URL}
      style={{
        display: 'grid',
        width: 'max-content',
        gridTemplateColumns: 'repeat(3, max-content)',
        color: statusColor,
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        px: 2,
        py: 1,
        mx: -2,
        textDecoration: 'none',
        cursor: 'pointer',
        bg: 'transparent',
        transition: 'background .2s ease',
        borderRadius: 2,
        ':hover': {
          bg: 'steel4',
        },
        ':focus-visible': {
          outline: 'none',
          boxShadow: 'focusRing',
          bg: 'steel4',
        },
      }}
    >
      <Text variant="helper2">{t(d.status)}</Text>
      <Dot size={8} color={statusColor} />
      <Text variant="body3" color="inherit">
        {statusText}
      </Text>
    </Anchor>
  )
}
