/** @jsxImportSource theme-ui */
import { useHasMounted, usePortal } from 'hooks'
import { ComponentProps, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { FocusOn } from 'react-focus-on'
import { TensorUIBaseProps } from 'typ'
import { Box } from '../../layout'
import { PopoverWrapper, PopoverWrapperProps } from './PopoverWrapper'

export type PopoverProps = TensorUIBaseProps &
  PopoverWrapperProps & {
    autoFocus?: boolean
    isFocusOn?: boolean
    isOpen: boolean
    onClose: () => void
    additionalShards?: ComponentProps<typeof FocusOn>['shards']
    returnFocus?: boolean
  }

export const Popover = forwardRef<HTMLUListElement, PopoverProps>(
  (
    {
      autoFocus = true,
      children,
      id,
      isFocusOn = true,
      isOpen,
      offset = [0, 4],
      onClose,
      placement = 'bottom-end',
      referenceEl,
      style,
      showArrow,
      arrowStyles,
      arrowSize,
      additionalShards = [],
      returnFocus = true,
      ...props
    },
    _ref
  ) => {
    const hasMounted = useHasMounted()
    const portal = usePortal(typeof document !== 'undefined' ? document : null, isOpen)
    const isReady = portal && hasMounted

    if (!isReady || !isOpen) return null
    return ReactDOM.createPortal(
      <>
        <FocusOn
          returnFocus={returnFocus}
          enabled={isFocusOn}
          onClickOutside={onClose}
          onEscapeKey={onClose}
          autoFocus={autoFocus}
          shards={additionalShards}
        >
          <Box data-testid={props['data-testid']} data-cy={props['data-cy']}>
            <PopoverWrapper
              offset={offset}
              placement={placement}
              referenceEl={referenceEl}
              style={style}
              isOpen={isOpen}
              showArrow={showArrow}
              arrowStyles={arrowStyles}
              arrowSize={arrowSize}
              id={id}
            >
              {children}
            </PopoverWrapper>
          </Box>
        </FocusOn>
      </>,
      portal
    )
  }
)
Popover.displayName = 'Popover'
