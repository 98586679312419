/** @jsxImportSource theme-ui */
import { FC, KeyboardEvent } from 'react'
import { useTheme } from 'theme'
import { StyleObject } from 'typ'
import { MenuItemProps } from './MenuItem'
import { ArrowRightIcon } from '../../icons/flat'

export interface MenuActionItemProps
  extends Omit<MenuItemProps, 'onClick' | 'options' | 'option' | 'selected'> {
  isFirst?: boolean
  label: string
  onClick: VoidFunction
}

export const MenuActionItem: FC<
  React.PropsWithChildren<MenuActionItemProps>
> = ({
  disabled,
  index,
  isFirst,
  label,
  onClick,
  onKeyDown,
  onMouseEnter,
  style,
  ...props
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLLIElement>, i: number) => {
    if (!disabled) onKeyDown(e, i)
  }
  const { styles } = useTheme()
  const dividerStyles: StyleObject = isFirst
    ? {
        mt: '5px',
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          height: '1px',
          background: 'steel3',
          width: '100%',
          left: 0,
          top: '-4px',
        },
      }
    : {}
  return (
    <li
      {...props}
      role="menuitem"
      aria-disabled={disabled}
      tabIndex={-1}
      onClick={onClick}
      onKeyDown={(e) => handleKeyDown(e, index)}
      onMouseEnter={onMouseEnter}
      sx={{
        ...styles.menuItem,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        bg: 'inherit',
        color: 'primary',
        fontWeight: 'medium',
        outline: 'none',
        cursor: 'pointer',
        ' :hover': {
          bg: 'highlight',
        },
        ':focus-visible': {
          bg: 'grayLight',
        },
        ...dividerStyles,
        ...style,
      }}
      data-testid={props['data-testid']}
      data-cy={props['data-cy'] ?? label}
    >
      <ArrowRightIcon size={12} sx={{ marginBottom: '1px' }} />
      <span>{label}</span>
    </li>
  )
}
