import { Maybe } from 'typ'
import { isHTMLElement } from 'typeguards'

export const getElementsFromContainer = (
  container: Maybe<HTMLElement>,
  tag: keyof HTMLElementTagNameMap
) => {
  if (!container || !tag) return []
  return Array.from(container.getElementsByTagName(tag)).filter(isHTMLElement)
}
