/** @jsxImportSource theme-ui */
import {
  forwardRef,
  Dispatch,
  SetStateAction,
  CSSProperties,
  PropsWithChildren,
} from 'react'
import { StyleObject, StyledProps, TestProps } from 'typ'
import { Box } from '../../layout'

export interface StaticWrapperProps extends StyledProps, TestProps {
  arrowSize?: number
  arrowStyles?: StyleObject
  id: string
  popperAttributes?: Record<string, string>
  popperStyles: CSSProperties
  setPopperElement: Dispatch<SetStateAction<any>>
  showArrow?: boolean
}
export const StaticWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<StaticWrapperProps>
>(
  (
    {
      id,
      setPopperElement,
      style,
      popperStyles,
      popperAttributes = {},
      children,
      arrowStyles,
      showArrow,
      arrowSize,
      ...props
    },
    ref
  ) => (
    <Box
      role="presentation"
      data-testid={props['data-testid'] ?? 'popover'}
      data-cy={props['data-cy']}
      id={id}
      ref={setPopperElement as any}
      style={{
        zIndex: 'popup',
        ...style,
        ...popperStyles,
        [`&[data-popper-placement^="bottom"] > #${id}-arrow`]: {
          top: `-${arrowSize ? arrowSize / 2 : 0}px`,
          borderBottom: 'none',
          borderRight: 'none',
        },
        [`&[data-popper-placement^="top"] > #${id}-arrow`]: {
          bottom: `-${arrowSize ? arrowSize / 2 : 0}px`,
          borderTop: 'none',
          borderLeft: 'none',
        },
        [`&[data-popper-placement^="left"] > #${id}-arrow`]: {
          right: `-${arrowSize ? arrowSize / 2 : 0}px`,
          borderBottom: 'none',
          borderLeft: 'none',
        },
        [`&[data-popper-placement^="right"] > #${id}-arrow`]: {
          left: `-${arrowSize ? arrowSize / 2 : 0}px`,
          borderTop: 'none',
          borderRight: 'none',
        },
      }}
      {...popperAttributes}
    >
      {showArrow && (
        <Box
          data-testid="popover-arrow"
          id={`${id}-arrow`}
          ref={ref}
          style={{
            ...arrowStyles,
            transform: `${(arrowStyles as any)?.transform ?? ''} rotate(45deg)`,
            height: `${arrowSize}px`,
            width: `${arrowSize}px`,
            zIndex: 10,
          }}
        />
      )}
      {children}
    </Box>
  )
)

StaticWrapper.displayName = 'StaticWrapper'
