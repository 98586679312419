/** @jsxImportSource theme-ui */
import { forwardRef, ReactNode } from 'react'
import { useTheme } from 'theme'
import { TensorUIBaseProps } from 'typ'
import { Paper } from '../../atoms'

export interface MenuWrapperProps extends TensorUIBaseProps {
  children: ReactNode
}
export const MenuWrapper = forwardRef<HTMLUListElement, MenuWrapperProps>(
  ({ children, style, ...props }, ref) => {
    const { roundedScrollbars } = useTheme()

    return (
      <Paper
        style={{
          overflow: 'hidden',
          bg: 'transparent',
          borderColor: 'steel1',
          boxShadow: 'menu',
          ...style,
        }}
      >
        <ul
          data-testid={props['data-testid'] ?? 'menu-wrapper'}
          data-cy={props['data-cy']}
          ref={ref}
          sx={{
            ...roundedScrollbars,
            display: 'grid',
            gap: '2px',
            px: 0,
            py: 0,
            margin: 0,
            bg: 'white',
            overflow: 'auto',
            maxHeight: '18rem',
            fontSize: 2,
          }}
        >
          {children}
        </ul>
      </Paper>
    )
  }
)

MenuWrapper.displayName = 'MenuWrapper'
