/** @jsxImportSource theme-ui */
import { AnimatePresence, motion } from 'framer-motion'
import { CSSProperties, Dispatch, FC, ReactNode, SetStateAction, useId } from 'react'
import { TensorUIBaseProps } from 'typ'
import { Box } from '../../layout'

export interface AnimatedWrapperProps extends TensorUIBaseProps {
  children: ReactNode
  id: string
  isOpen?: boolean
  popperAttributes?: Record<string, string>
  popperStyles: CSSProperties
  setPopperElement: Dispatch<SetStateAction<any>>
  transformOrigin?: string
}

const TIMING = [0, 0.8, 0.5, 1]
const DURATION = 0.15
const CLOSED_TRANSFORM = 'scale(0.8, 0.7)'
export const AnimatedWrapper: FC<AnimatedWrapperProps> = ({
  children,
  id,
  isOpen,
  popperAttributes = {},
  popperStyles,
  setPopperElement,
  style,
  transformOrigin = 'top right',
  ...props
}) => {
  const key = useId()

  return (
    <AnimatePresence>
      {isOpen && (
        <Box
          role="presentation"
          id={id}
          ref={setPopperElement}
          data-testid={props['data-testid'] ?? 'popover'}
          data-cy={props['data-cy']}
          style={{
            zIndex: 'popup',
            ...style,
            ...popperStyles,
          }}
          {...popperAttributes}
        >
          <motion.div
            key={key}
            style={{
              transformOrigin,
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transform: 'scale(1, 1)',
              transition: {
                type: 'keyframes',
                ease: TIMING,
                duration: DURATION,
              },
            }}
            initial={{
              opacity: 0,
              transform: CLOSED_TRANSFORM,
            }}
            exit={{
              opacity: 0,
              transform: CLOSED_TRANSFORM,
              transition: {
                type: 'keyframes',
                ease: TIMING,
                duration: DURATION,
                opacity: {
                  duration: 0.1,
                },
              },
            }}
          >
            {children}
          </motion.div>
        </Box>
      )}
    </AnimatePresence>
  )
}
