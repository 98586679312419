export type DevelopmentSection = 'modeling' | 'ppa-design' | 'scenarios' | 'reports' | 'sharing'
export type ManagementSection = 'spv-management'
export type OperationsSection =
  | 'generation-forecasts'
  | 'reporting'
  | 'price-forecasts'
  | 'live-assets'
  | 'market-data'
export type SecuritySection = 'secure' | 'authentication' | 'data-residency'

export const BASE_URL_JP = 'https://www.tensorenergy.jp'
export const BASE_URL_EN = 'https://www.tensorenergy.jp/en'

export const buildWebsiteUrl = (lang: string, path: string = '') =>
  `${!lang || lang === 'ja' ? BASE_URL_JP : BASE_URL_EN}${path}`
export const buildAlternateLangWebsiteUrl = (lang: string, path: string = '') =>
  `${!lang || lang === 'ja' ? BASE_URL_EN : BASE_URL_JP}${path}`

export const buildCanonicalAndLangSeoProps = (lang: string, path: string = '') => ({
  canonical: buildWebsiteUrl(lang, path),
  languageAlternates: [
    {
      hrefLang: lang === 'ja' ? 'en' : 'ja',
      href: buildAlternateLangWebsiteUrl(lang, path),
    },
    {
      hrefLang: lang === 'ja' ? 'ja' : 'en',
      href: buildWebsiteUrl(lang, path),
    },
    {
      hrefLang: 'x-default',
      href: `${BASE_URL_JP}${path}`,
    },
  ],
})

export const COMPANY_URL = '/company'
export const CONTACT_URL = '/contact'
export const FIP_URL = '/fip'
export const INSIGHTS_URL = '/insights'
export const PRESS_URL = '/press'
export const PRICING_URL = '/pricing'
export const BATTERY_STORAGE_OPTIMIZATION_URL = '/battery-storage-optimization'
export const CAREERS_URL = 'https://www.linkedin.com/company/tensorenergy/jobs/'

// SECTIONS
export const BATTERY_ASSET_MANAGEMENT_ID = 'asset-management'
export const BATTERY_FAST_RESULTS_ID = 'fast-results'
export const BATTERY_TOTAL_TRANSPARENCY_ID = 'total-transparency'
export const BATTERY_AUTOMATED_OPERATIONS_ID = 'automated-operations'
// FEATURES
export const FEATURES_URL = '/features'
export const FEATURES_DEVELOPMENT_URL = `${FEATURES_URL}/development`
export const FEATURES_OPERATIONS_URL = `${FEATURES_URL}/operations`
export const FEATURES_MANAGEMENT_URL = `${FEATURES_URL}/management`
export const FEATURES_SECURITY_URL = `${FEATURES_URL}/security`

export const getDevelopmentSectionUrl = (section: DevelopmentSection) =>
  `${FEATURES_DEVELOPMENT_URL}#${section}`
export const getManagementSectionUrl = (section: ManagementSection) =>
  `${FEATURES_MANAGEMENT_URL}#${section}`
export const getOperationsSectionUrl = (section: OperationsSection) =>
  `${FEATURES_OPERATIONS_URL}#${section}`
export const getSecuritySectionUrl = (section: SecuritySection) =>
  `${FEATURES_SECURITY_URL}#${section}`

// EXTERNAL SERVICES
export const WEBSITE_STATUS_TRACKER_URL = 'https://status.tensorenergy.jp/'
