/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { TensorUIBaseProps } from 'typ'
import { Box, Grid } from '../../layout'

export interface DotProps extends TensorUIBaseProps {
  color: string
  size?: number
}
export const Dot: FC<DotProps> = ({ color, size = 9, style, ...props }) => (
  <Grid
    style={{
      width: '100%',
      placeItems: 'center',
      ...style,
    }}
  >
    <Box
      data-testid={props['data-testid'] ?? 'dot'}
      data-cy={props['data-cy']}
      style={{
        backgroundColor: color,
        borderRadius: 'max',
        height: `${size}px`,
        width: `${size}px`,
      }}
    />
  </Grid>
)
