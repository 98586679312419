/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { SvgIconProps } from '../types'

export const ChevronDownIcon: FC<SvgIconProps> = ({
  size = 16,
  color = '#0c5cf3',
  style = {},
  ...props
}) => (
  <svg
    sx={{ height: `${size}px`, ...style }}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="chevron-down-icon"
    {...props}
  >
    <path
      d="M2 5L7.64645 10.6464C7.84171 10.8417 8.15829 10.8417 8.35355 10.6464L14 5"
      sx={{ stroke: color }}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
