/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { TensorUIBaseProps } from 'typ'
import { keyframes } from '@emotion/react'
import { Box } from '../../layout'

export interface ModalOverlayProps extends TensorUIBaseProps {
  bg?: string
  id?: string
  isOpen: boolean
}

export const ModalOverlay: FC<React.PropsWithChildren<ModalOverlayProps>> = ({
  bg,
  isOpen,
  style,
  ...props
}) => {
  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 0.2;
    }
  `
  const fadeOut = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0;
  }
`

  return (
    <Box
      data-testid={props['data-testid'] ?? 'overlay'}
      data-cy={props['data-cy']}
      role="presentation"
      style={{
        animation: isOpen ? `${fadeIn} .3s` : `${fadeOut} .3s`,
        position: 'fixed',
        inset: '0px',
        opacity: 0.2,
        zIndex: 'modal-overlay-0',
        bg: bg ?? 'black',
        pointerEvents: isOpen ? 'auto' : 'none',
        ...style,
      }}
      tabIndex={-1}
    />
  )
}
