import { useState, useEffect } from 'react'

export const useDelayedRemoval = (isOpen: boolean, delay: number = 300) => {
  const [isDisplayed, setIsDisplayed] = useState(false)
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isOpen && !isDisplayed) setIsDisplayed(true)
    if (!isOpen && isDisplayed) {
      timeout = setTimeout(() => setIsDisplayed(false), delay)
    }
    return () => clearTimeout(timeout)
  }, [isOpen, isDisplayed, delay])
  return { isDisplayed }
}
