/** @jsxImportSource theme-ui */
import { FC, ReactNode } from 'react'
import { TestProps } from 'typ'
import { Box } from '../../layout'

export interface MenuGroupLabelProps extends TestProps {
  children: ReactNode
}
export const MenuGroupLabel: FC<React.PropsWithChildren<MenuGroupLabelProps>> = ({
  children,
  ...props
}) => (
  <Box
    data-testid={props['data-testid']}
    data-cy={props['data-cy']}
    style={{
      color: 'text',
      lineHeight: 'body',
      fontFamily: 'body',
      fontSize: 2,
      fontWeight: 'medium',
      cursor: 'default',
      px: 2,
      py: 1,
    }}
  >
    {children}
  </Box>
)
