import { FC } from 'react'
import { noSelect } from 'theme'
// eslint-disable-next-line no-restricted-imports
import { Label as ThemeUILabel } from 'theme-ui'
import { StyledProps } from 'typ'

export interface CheckboxLabelProps extends StyledProps {
  hideLabel?: boolean
  id: string
  label?: string
}
export const CheckboxLabel: FC<React.PropsWithChildren<CheckboxLabelProps>> = ({
  label,
  id,
  hideLabel,
  style,
}) => (
  // Need these to avoid propagation even without the label itself being focusable
  // eslint-disable-next-line
  <ThemeUILabel
    variant={hideLabel ? 'hidden' : 'label'}
    sx={{
      ...noSelect,
      whiteSpace: 'nowrap',
      pl: 3,
      mb: 0,
      cursor: 'pointer',
      ...style,
    }}
    onClick={(e) => e.stopPropagation()}
    onKeyDown={(e) => e.stopPropagation()}
    htmlFor={id}
  >
    {label}
  </ThemeUILabel>
)
