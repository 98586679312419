/** @jsxImportSource theme-ui */
import { LinkedinLink } from '@/components/LinkedinLink'
import { SystemStatusIndicator } from '@/components/SystemStatusIndicator'
import { TensorFullNameSmallLogo } from '@/components/logos/TensorFullNameSmallLogo'
import { useSystemStatus } from '@/contexts/SystemStatusContext'
import { getDocsLink } from 'links'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import {
  CAREERS_URL,
  COMPANY_URL,
  CONTACT_URL,
  FEATURES_URL,
  INSIGHTS_URL,
  PRESS_URL,
  PRICING_URL,
} from 'src/assets/links'
import { useTheme } from 'theme'
import { Anchor, Container, Flex, Grid, NextLink, Text } from 'ui'

export interface FooterProps {}

type Link = {
  text: string
  href: string
  type: 'anchor' | 'link'
}

type LinksBlockProps = {
  title: string
  links: Link[]
}

export const d = {
  about: 'tensor-website-common:footer.about',
  product: 'tensor-website-common:footer.product',
  features: 'tensor-website-common:footer.features',
  pricing: 'tensor-website-common:footer.pricing',
  documentation: 'tensor-website-common:footer.documentation',
  company: 'tensor-website-common:footer.company',
  aboutUs: 'tensor-website-common:footer.about-us',
  careers: 'tensor-website-common:footer.careers',
  resources: 'tensor-website-common:footer.resources',
  contact: 'tensor-website-common:footer.contact',
  insights: 'tensor-website-common:footer.insights',
  press: 'tensor-website-common:footer.press',
  legal: 'tensor-website-common:footer.legal',
}

const listItemStyle = {
  p: 0,
  m: 0,
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
}

const LinksBlock: FC<LinksBlockProps> = ({ title, links }) => {
  const theme = useTheme()
  return (
    <Grid
      gap="12px"
      style={{
        height: 'max-content',
        width: '150px',
        minWidth: '150px',
        mb: 3,
      }}
    >
      <Text variant="body3">{title}</Text>
      <ul
        sx={{
          margin: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        {links.map(({ text, href, type }) =>
          type === 'anchor' ? (
            <li key={text} sx={listItemStyle}>
              <Anchor
                href={href}
                key={text}
                target="_blank"
                rel="noreferrer"
                style={theme.styles.link}
              >
                {text}
              </Anchor>
            </li>
          ) : (
            <li key={text} sx={listItemStyle}>
              <NextLink href={href} key={text} style={theme.styles.link}>
                {text}
              </NextLink>
            </li>
          )
        )}
      </ul>
    </Grid>
  )
}

export const Footer: FC<FooterProps> = () => {
  const { t, lang } = useTranslation()
  const { status } = useSystemStatus()

  const linkItems: LinksBlockProps[] = [
    {
      title: t(d.product),
      links: [
        { text: t(d.features), href: FEATURES_URL, type: 'link' },
        { text: t(d.pricing), href: PRICING_URL, type: 'link' },
        {
          text: t(d.documentation),
          href: getDocsLink('/', lang),
          type: 'anchor',
        },
      ],
    },
    {
      title: t(d.company),
      links: [
        { text: t(d.aboutUs), href: COMPANY_URL, type: 'link' },
        {
          text: t(d.careers),
          href: CAREERS_URL,
          type: 'anchor',
        },
      ],
    },
    {
      title: t(d.resources),
      links: [
        { text: t(d.contact), href: CONTACT_URL, type: 'link' },
        { text: t(d.insights), href: INSIGHTS_URL, type: 'link' },
        {
          text: t(d.press),
          href: PRESS_URL,
          type: 'link',
        },
        {
          text: t(d.legal),
          href: getDocsLink('/legal/terms-of-use', lang),
          type: 'anchor',
        },
      ],
    },
  ]

  return (
    <Grid
      style={{
        placeItems: 'center',
        width: ['100%'],
        bg: 'primaryTint1',
        borderTop: '1px solid',
        borderColor: 'steel3',
      }}
    >
      <Container
        style={{
          pt: [5, 5, 7],
          pb: [6, 5, 8],
        }}
      >
        <Flex
          as="footer"
          style={{
            width: ['100%'],
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: [5],
            columnGap: [9],
          }}
        >
          <Grid
            style={{
              width: ['250px'],
              pr: 3,
            }}
            gap={3}
          >
            <TensorFullNameSmallLogo />
            <SystemStatusIndicator status={status} />
            <Text
              variant="secondary3"
              style={{
                '@media screen and (max-width: 300px)': {
                  width: 'calc(100vw - 48px)',
                },
              }}
            >
              {t(d.about)}
            </Text>
            <LinkedinLink href="https://www.linkedin.com/company/tensorenergy/" />
          </Grid>
          <Flex
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 3,
            }}
          >
            {linkItems.map((block) => (
              <LinksBlock key={block.title} title={block.title} links={block.links} />
            ))}
          </Flex>
        </Flex>
      </Container>
    </Grid>
  )
}
