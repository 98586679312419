/* eslint-disable sonarjs/cognitive-complexity */
/** @jsxImportSource theme-ui */
import { KeyboardEvent } from 'react'
import { StyleObject } from 'typ'
import { sizeMap } from './styles'
import { CheckboxWrapperProps } from '../types'
import { Box, Flex } from '../../../layout'

export const Wrapper: React.FC<
  React.PropsWithChildren<CheckboxWrapperProps>
> = ({
  Toggle,
  boxStyles,
  checked = false,
  children,
  disabled,
  size = 'md',
  style,
}) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code !== 'Escape') e.stopPropagation()
  }
  const mappedSize = `${sizeMap[size]}px`
  const coreBoxStyles = {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
  } as StyleObject
  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleKeyDown}
      data-testid="checkbox-wrapper"
      role="presentation"
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        pointerEvents: 'auto',
        height: mappedSize,
        width: mappedSize,
        minHeight: mappedSize,
        minWidth: mappedSize,
        ...style,
      }}
    >
      <Box
        style={
          boxStyles
            ? {
                ...coreBoxStyles,
                ...boxStyles,
              }
            : {
                ...coreBoxStyles,
                height: mappedSize,
                width: mappedSize,
                minHeight: mappedSize,
                minWidth: mappedSize,
                border: '1px solid',
                borderColor:
                  disabled && checked
                    ? 'primaryTint3'
                    : disabled
                    ? 'steel1'
                    : checked
                    ? 'primary'
                    : 'grayText',
                borderRadius: 1,
                bg:
                  disabled && checked
                    ? 'primaryTint3'
                    : disabled
                    ? 'steel3'
                    : checked
                    ? 'primary'
                    : 'white',
                overflow: 'visible',
                ':focus-within': {
                  boxShadow: 'focusRing',
                  borderColor: 'primaryShade2',
                },
                ':hover': {
                  borderColor:
                    disabled && checked
                      ? 'primaryTint3'
                      : disabled
                      ? 'steel1'
                      : 'primary',
                },
              }
        }
      >
        <Flex
          style={{
            position: 'absolute',
            inset: 0,
            flexDirection: 'row-reverse',
            alignItems: 'center',
          }}
        >
          {children}
        </Flex>
        {Toggle}
      </Box>
    </Box>
  )
}
