/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { Anchor, LinkedInIcon } from 'ui'

export interface LinkedinLinkProps {
  href: string
}
export const LinkedinLink: FC<LinkedinLinkProps> = ({ href }) => (
  <Anchor
    aria-label="Linkedin"
    href={href}
    target="_blank"
    rel="noreferrer"
    style={{
      display: 'block',
      fill: 'textGray',
      transition: 'fill 0.2s ease',
      '&:focus-visible': {
        boxShadow: 'focusRing',
      },
      '&:hover': {
        fill: 'text',
      },
      padding: 0,
      margin: 0,
      height: '20px',
      width: '20px',
      textDecoration: 'none',
      outline: 'none',
    }}
    data-testid="linkedin-icon"
  >
    <LinkedInIcon color="inherit" size={20} />
  </Anchor>
)
