/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { SvgIconProps } from '../types'

export const CloseIcon: FC<SvgIconProps> = ({
  size = 16,
  color = '#0c5cf3',
  style = {},
  ...props
}) => (
  <svg
    sx={{ height: `${size}px`, ...style }}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="close-icon"
    {...props}
  >
    <path d="M2.5 2.5L13.5 13.5" sx={{ stroke: color }} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M13.5 2.5L2.5 13.5" sx={{ stroke: color }} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)
