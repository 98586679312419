/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { StyledProps } from 'typ'

export const TensorFullNameLogo: FC<StyledProps> = ({ style }) => (
  <svg
    width="181"
    height="25"
    viewBox="0 0 181 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    <path
      d="M52.6935 7.54851H48.067V21.3797H45.1118V7.54851H40.4854V4.90823H52.6935V7.54851V7.54851Z"
      fill="#131523"
    />
    <path
      d="M55.5522 16.3415C55.5522 18.3763 56.3758 19.321 58.241 19.321C59.767 19.321 60.5906 18.6669 60.7117 17.5769H63.4973C63.3277 20.2654 61.4625 21.6703 58.241 21.6703C54.5589 21.6703 52.6455 19.8294 52.6455 16.3173V13.8466C52.6455 10.3826 54.5349 8.51749 58.0957 8.51749C61.6564 8.51749 63.5698 10.4069 63.5698 13.8708V15.9538H55.5522V16.3415H55.5522ZM55.5522 13.798H60.8085C60.8085 11.7633 60.0091 10.8671 58.1924 10.8671C56.3516 10.8671 55.5522 11.7633 55.5522 13.798Z"
      fill="#131523"
    />
    <path
      d="M76.9412 13.0714V21.3797H74.0345V13.3378C74.0345 11.7876 73.4533 11.0852 72.2662 11.0852C71.0551 11.0852 70.1346 11.7876 69.4323 13.2168V21.3797H66.5254V8.80813H68.9961L69.0931 10.528C70.1104 9.12303 71.3457 8.51749 72.993 8.51749C75.6091 8.51749 76.9412 10.0677 76.9412 13.0714V13.0714Z"
      fill="#131523"
    />
    <path
      d="M79.3877 17.6011H82.1733C82.1733 18.8365 82.9484 19.442 84.6683 19.442C86.1943 19.442 86.9694 18.9333 86.9694 17.9643C86.9694 16.9228 86.1942 16.4626 84.0627 16.2205C80.8652 15.8328 79.6541 14.8155 79.6541 12.4902C79.6541 9.89815 81.4224 8.51749 84.644 8.51749C87.8655 8.51749 89.5611 9.87388 89.5611 12.4659H86.7512C86.7512 11.3031 86.0732 10.7461 84.6197 10.7461C83.2391 10.7461 82.5125 11.2788 82.5125 12.1753C82.5125 13.1682 83.2391 13.5559 85.2981 13.798C88.5681 14.1615 89.8278 15.2997 89.8278 17.6979C89.8278 20.3382 88.0837 21.6703 84.644 21.6703C81.0833 21.6703 79.3877 20.3139 79.3877 17.6011L79.3877 17.6011Z"
      fill="#131523"
    />
    <path
      d="M91.9834 16.1962V13.9919C91.9834 10.3583 93.8728 8.51746 97.5546 8.51746C101.236 8.51746 103.15 10.3583 103.15 13.9919V16.1962C103.15 19.8294 101.237 21.6703 97.5546 21.6703C93.8725 21.6703 91.9834 19.8294 91.9834 16.1962ZM100.243 16.1234V14.0644C100.243 11.9086 99.3955 10.9399 97.5546 10.9399C95.7137 10.9399 94.8903 11.9086 94.8903 14.0644V16.1234C94.8903 18.2792 95.7137 19.2482 97.5546 19.2482C99.3955 19.2482 100.243 18.2792 100.243 16.1234Z"
      fill="#131523"
    />
    <path
      d="M113.009 8.56601V11.3273H112.355C110.417 11.3273 109.4 12.1753 109.037 14.2582V21.3797H106.13V8.80812H108.601L108.722 11.1092C109.279 9.36541 110.345 8.56602 112.113 8.56602H113.009V8.56601Z"
      fill="#131523"
    />
    <path
      d="M120.736 15.0577V11.2306C120.736 6.74942 122.747 4.61761 127.01 4.61761C131.055 4.61761 133.042 6.55557 133.163 10.6008H130.328C130.232 8.25113 129.238 7.20964 127.034 7.20964C124.66 7.20964 123.691 8.42072 123.691 11.2788V15.0091C123.691 17.8675 124.66 19.0786 127.034 19.0786C129.238 19.0786 130.232 18.0371 130.328 15.7117H133.163C133.042 19.7327 131.055 21.6703 127.01 21.6703C122.747 21.6703 120.736 19.5388 120.736 15.0577V15.0577Z"
      fill="#131523"
    />
    <path
      d="M136.094 3.69714H139.001V21.3797H136.094V3.69714Z"
      fill="#131523"
    />
    <path
      d="M142.004 16.1962V13.9919C142.004 10.3583 143.893 8.51746 147.575 8.51746C151.257 8.51746 153.171 10.3583 153.171 13.9919V16.1962C153.171 19.8294 151.257 21.6703 147.575 21.6703C143.893 21.6703 142.004 19.8294 142.004 16.1962V16.1962ZM150.264 16.1234V14.0644C150.264 11.9086 149.416 10.9399 147.575 10.9399C145.734 10.9399 144.911 11.9086 144.911 14.0644V16.1234C144.911 18.2792 145.734 19.2482 147.575 19.2482C149.416 19.2482 150.264 18.2792 150.264 16.1234Z"
      fill="#131523"
    />
    <path
      d="M166.517 8.80811V21.3797H164.047L163.95 19.6116C162.908 21.0405 161.673 21.6703 160.002 21.6703C157.362 21.6703 156.005 20.1201 156.005 17.1166V8.80811H158.912V16.85C158.912 18.4245 159.493 19.1028 160.728 19.1028C161.94 19.1028 162.908 18.4245 163.611 16.9228V8.80811H166.517H166.517Z"
      fill="#131523"
    />
    <path
      d="M180.591 3.69714V21.3797H178.096L177.999 19.6599C177.006 21.0405 175.771 21.6703 174.075 21.6703C171.193 21.6703 169.521 19.9022 169.521 16.4869V13.7255C169.521 10.3101 171.193 8.51749 174.075 8.51749C175.577 8.51749 176.74 9.02626 177.684 10.1163V3.69714H180.591V3.69714ZM177.684 16.8985V13.2893C177.006 11.7633 175.989 10.9399 174.632 10.9399C173.228 10.9399 172.428 11.8361 172.428 13.7495V16.4383C172.428 18.3277 173.228 19.2482 174.657 19.2482C175.989 19.2482 177.006 18.4245 177.684 16.8985V16.8985Z"
      fill="#131523"
    />
    <g style={{ mixBlendMode: 'multiply', opacity: '0.25' }}>
      <path
        d="M17.5015 25H5.61977C4.9948 25 4.64191 24.6656 4.83153 24.2532L7.84423 17.7005C8.03386 17.2881 8.69422 16.9537 9.31918 16.9537H21.2009C21.8259 16.9537 22.1788 17.2881 21.9892 17.7005L18.9765 24.2532C18.7869 24.6656 18.1265 25 17.5015 25V25Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply', opacity: '0.5' }}>
      <path
        d="M22.0418 18.2738H3.08755C2.46258 18.2738 2.10968 17.9395 2.2993 17.527L6.05189 9.36512C6.24152 8.95268 6.90188 8.61832 7.52685 8.61832H26.4811C27.1061 8.61832 27.459 8.95268 27.2693 9.36512L23.5167 17.527C23.3271 17.9395 22.6668 18.2738 22.0418 18.2738H22.0418Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M26.8662 11.2648H0.839499C0.21453 11.2648 -0.138365 10.9304 0.0512529 10.518L4.54373 0.746805C4.73337 0.33436 5.39372 0 6.01869 0H32.0454C32.6704 0 33.0233 0.33436 32.8337 0.746805L28.3412 10.518C28.1516 10.9304 27.4912 11.2648 26.8662 11.2648H26.8662Z"
        fill="#4082FF"
      />
    </g>
  </svg>
)
