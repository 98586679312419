/* eslint-disable sonarjs/cognitive-complexity */
/** @jsxImportSource theme-ui */
import { KeyboardEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { toLowerCase } from 'string'
import { StyleObject, TensorUIBaseProps } from 'typ'
import { isFocusable } from 'typeguards'
import { useOptions } from '../../hooks'
import { ActionOption, Option, OptionGroup } from '../../types'
import { getElementsFromContainer } from '../../utils'
import { MenuActionItem } from './MenuActionItem'
import { MenuGroupLabel } from './MenuGroupLabel'
import { MenuItem } from './MenuItem'
import { MenuWrapper } from './MenuWrapper'

type Selection = string | number
type BaseProps = {
  actions?: ActionOption[]
  disabledValues?: any[]
  emptyOptionLabel?: string
  ignoreSelection?: boolean
  onChange: (value: any) => void
  onEscape?: VoidFunction
  onTextKeyDown?: (val: string) => void
  onClose?: VoidFunction
  optionGroups?: OptionGroup[]
  options?: Option[]
  paperStyle?: StyleObject
  sorted?: boolean
  showSelectionTick?: boolean
} & TensorUIBaseProps

type SingleProps = BaseProps & {
  isMulti?: never
  selected?: Selection
}

type MultiProps = BaseProps & {
  isMulti?: true
  selected?: Selection[]
}
export type MenuCoreProps = SingleProps | MultiProps

export const MenuCore = forwardRef<HTMLDivElement, MenuCoreProps>(
  (
    {
      actions = [],
      disabledValues = [],
      emptyOptionLabel,
      ignoreSelection,
      isMulti,
      onChange,
      onClose,
      onEscape,
      onTextKeyDown,
      optionGroups = [],
      options = [],
      paperStyle = {},
      selected,
      showSelectionTick,
      sorted = true,
      ...props
    },
    ref
  ) => {
    const [areMouseEffectsDisabled, setAreMouseEffectsDisabled] = useState(true)
    const itemsRef = useRef<HTMLUListElement>(null)
    const [currentSearch, setCurrentSearch] = useState('')

    const handleClick = (value: any) => {
      onChange(value)
      onClose?.()
    }
    const handleCheckboxClick = (value: any, i: number) => {
      onChange(value)
      const listItems = getElementsFromContainer(itemsRef.current, 'li')
      const targetItem = listItems[i]
      if (isFocusable(targetItem)) targetItem.focus()
    }
    const handleActionClick = (action: ActionOption['action']) => {
      action()
      onClose?.()
    }

    const handleMouseMove = useCallback(() => {
      setAreMouseEffectsDisabled(false)
    }, [])

    const getIsSelected = useCallback(
      (option: Option) => {
        if (isMulti && Array.isArray(selected)) {
          return selected.length ? selected.includes(option.value) : undefined
        }
        return selected ? option.value === selected : undefined
      },
      [isMulti, selected]
    )
    const { sortedOptions, sortedOptionGroups, allOptions, getGroupOptionIndex } = useOptions({
      options,
      optionGroups,
      sorted,
      emptyOptionLabel,
    })

    const handleFindIndex = useCallback(
      (val: string) => {
        const foundIndex = allOptions.findIndex(
          (option) => toLowerCase(option.label).slice(0, val.length) === toLowerCase(val)
        )
        const target = getElementsFromContainer(itemsRef.current, 'li')[foundIndex]
        if (isFocusable(target)) {
          target.focus()
        }
      },
      [allOptions, itemsRef]
    )

    const handleKeyDown = (e: KeyboardEvent, index: number, action?: VoidFunction) => {
      e.preventDefault()
      e.stopPropagation()
      const listItems = getElementsFromContainer(itemsRef.current, 'li')
      const nextItem = listItems[index + 1]
      const prevItem = listItems[index - 1]
      setAreMouseEffectsDisabled(true)
      if (!listItems) return
      switch (e.code) {
        case 'ArrowDown':
          if (isFocusable(nextItem)) nextItem.focus()
          break
        case 'ArrowUp':
          if (isFocusable(prevItem)) prevItem.focus()
          if (index === 0) itemsRef.current?.scrollTo({ top: 0 })
          break
        case 'Tab': {
          e.preventDefault()
          if (e.shiftKey) {
            if (isFocusable(prevItem)) prevItem.focus()
            if (index === 0) itemsRef.current?.scrollTo({ top: 0 })
          } else if (isFocusable(nextItem)) {
            nextItem.focus()
          }
          break
        }
        case 'Delete': {
          if (isMulti && selected?.includes(allOptions[index].value)) {
            handleCheckboxClick(allOptions[index].value, index)
          } else {
            onClose?.()
          }
          break
        }
        case 'Space':
          if (isMulti) {
            handleCheckboxClick(allOptions[index].value, index)
          } else {
            handleClick(allOptions[index].value)
          }
          break
        case 'Enter':
        case 'NumpadEnter':
          if (action) {
            action()
            onClose?.()
          } else handleClick(allOptions[index].value)
          break
        case 'Escape':
          if (onEscape) onEscape()
          else onClose?.()
          break
        default:
          if (/^(?=.{1,1}$)[A-Za-z0-9]/.test(e.key)) {
            if (onTextKeyDown) onTextKeyDown(e.key)
            else setCurrentSearch((prev) => prev + e.key)
          }
      }
    }

    useEffect(() => {
      let timeout: NodeJS.Timeout
      if (currentSearch) {
        handleFindIndex(currentSearch)
        timeout = setTimeout(() => setCurrentSearch(''), 500)
      }
      return () => {
        clearTimeout(timeout)
      }
    }, [currentSearch, handleFindIndex])

    return (
      <div ref={ref} onMouseMove={handleMouseMove}>
        <MenuWrapper
          ref={itemsRef}
          style={{ p: 1, bg: 'white', ...paperStyle }}
          data-testid={props['data-testid']}
          data-cy={props['data-cy']}
        >
          {sortedOptions.map((option, index) => (
            <MenuItem
              areMouseEffectsDisabled={areMouseEffectsDisabled}
              selected={ignoreSelection ? false : getIsSelected(option)}
              key={`${index}-${option.value}`}
              option={option}
              index={index}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              disabled={Boolean(disabledValues.length) && disabledValues.includes(option.value)}
              onCheckboxClick={handleCheckboxClick}
              variant={option.variant}
              style={option.style}
              isMulti={isMulti}
              showSelectionTick={showSelectionTick}
            />
          ))}
          {sortedOptionGroups.map((group, groupIndex) => (
            <div key={group.label}>
              <MenuGroupLabel>{group.label}</MenuGroupLabel>
              {group.options.map((option, index) => {
                const isSelected = getIsSelected(option)
                return (
                  <MenuItem
                    areMouseEffectsDisabled={areMouseEffectsDisabled}
                    selected={ignoreSelection ? false : isSelected}
                    key={`${groupIndex}-${index}-${option.value}`}
                    option={option}
                    index={getGroupOptionIndex(index, groupIndex, sortedOptionGroups)}
                    onClick={handleClick}
                    onCheckboxClick={handleCheckboxClick}
                    onKeyDown={handleKeyDown}
                    disabled={
                      Boolean(disabledValues.length) && disabledValues.includes(option.value)
                    }
                    variant={option.variant}
                    style={option.style}
                    isMulti={isMulti}
                    showSelectionTick={showSelectionTick}
                  />
                )
              })}
            </div>
          ))}
          {actions.map(({ label, action }, index) => (
            <MenuActionItem
              index={allOptions.length + 1 + index}
              onClick={() => handleActionClick(action)}
              onKeyDown={(e) => {
                handleKeyDown(e, allOptions.length + index, action)
              }}
              key={`${index}-${label}`}
              label={label}
              isFirst={index === 0}
            />
          ))}
        </MenuWrapper>
      </div>
    )
  }
)

MenuCore.displayName = 'MenuCore'
