/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  IconButton as ThemeUIIconButton,
  IconButtonProps as ThemeUIIconButtonProps,
} from 'theme-ui'
import { TensorUIBaseProps } from 'typ'
import { CloseIcon } from '../../icons'

export type CloseProps = Omit<ThemeUIIconButtonProps, 'sx' | 'style' | 'size'> &
  TensorUIBaseProps

export const Close = forwardRef<HTMLButtonElement, CloseProps>(
  ({ style, ...props }, ref) => (
    <ThemeUIIconButton
      {...props}
      title="Close"
      aria-label="Close"
      data-testid={props['data-testid']}
      data-cy={props['data-cy']}
      sx={{
        ...style,
        stroke: 'textGray',
        cursor: 'pointer',
        outline: 'none',
        ':hover': {
          stroke: 'primary',
        },
        ':focus-visible': {
          stroke: 'primary',
          boxShadow: 'focusRing',
        },
        ':disabled': {
          stroke: 'textGray',
          cursor: 'not-allowed',
        },
      }}
      ref={ref}
    >
      <CloseIcon color="inherit" />
    </ThemeUIIconButton>
  )
)

Close.displayName = 'Close'
