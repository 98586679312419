/** @jsxImportSource theme-ui */
import { CSSProperties, FC } from 'react'
import { TestProps } from 'typ'

export type BrandBlockSvgProps = {
  size?: number
  style?: CSSProperties
} & TestProps

export const BrandBlockSvg: FC<BrandBlockSvgProps> = ({ size = 34, style = {}, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 772.1104 171.0285"
    {...props}
    style={{ height: `${size}px`, ...style }}
    data-testid={props['data-testid'] ?? 'brand-block-svg'}
  >
    <g>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Tensor_Logo_on_White" data-name="Tensor Logo on White">
            <g>
              <path
                fill="#131523"
                d="M359.4814,51.6312h-31.6514v94.6211h-20.2168V51.6312h-31.6504v-18.0625h83.5186v18.0625Z"
              />
              <path
                fill="#131523"
                d="M379.0381,111.7845c0,13.9199,5.6338,20.3828,18.3936,20.3828,10.4395,0,16.0742-4.4746,16.9023-11.9316h19.0566c-1.1602,18.3936-13.9199,28.0049-35.959,28.0049-25.1885,0-38.2793-12.5938-38.2793-36.6221v-16.9023c0-23.6963,12.9258-36.4561,37.2852-36.4561s37.4502,12.9258,37.4502,36.6221v14.251h-54.8496v2.6514Zm0-17.3994h35.959c0-13.9199-5.4688-20.0508-17.8965-20.0508-12.5947,0-18.0625,6.1309-18.0625,20.0508Z"
              />
              <path
                fill="#131523"
                d="M525.3623,89.4134v56.8389h-19.8857v-55.0156c0-10.6055-3.9766-15.4111-12.0967-15.4111-8.2852,0-14.582,4.8057-19.3877,14.582v55.8447h-19.8857V60.2484h16.9023l.6631,11.7656c6.96-9.6113,15.4111-13.7539,26.6797-13.7539,17.8965,0,27.0107,10.6055,27.0107,31.1533Z"
              />
              <path
                fill="#131523"
                d="M542.1006,120.4017h19.0566c0,8.4512,5.3027,12.5938,17.0684,12.5938,10.4404,0,15.7432-3.4795,15.7432-10.1084,0-7.125-5.3027-10.2744-19.8857-11.9307-21.874-2.6514-30.1592-9.6113-30.1592-25.5195,0-17.7314,12.0967-27.1768,34.1367-27.1768s33.6387,9.2793,33.6387,27.0107h-19.2227c0-7.9541-4.6396-11.7656-14.582-11.7656-9.4453,0-14.417,3.6455-14.417,9.7773,0,6.7939,4.9717,9.4453,19.0566,11.1025,22.3711,2.4854,30.9883,10.2734,30.9883,26.6797,0,18.0615-11.9316,27.1758-35.4619,27.1758-24.3604,0-35.96-9.2793-35.96-27.8389Z"
              />
              <path
                fill="#131523"
                d="M628.2715,110.7904v-15.0801c0-24.8564,12.9258-37.4502,38.1133-37.4502s38.2793,12.5938,38.2793,37.4502v15.0801c0,24.8564-13.0908,37.4502-38.2793,37.4502s-38.1133-12.5938-38.1133-37.4502Zm56.5068-.4971v-14.0859c0-14.748-5.7998-21.376-18.3936-21.376s-18.2275,6.6279-18.2275,21.376v14.0859c0,14.748,5.6338,21.377,18.2275,21.377s18.3936-6.6289,18.3936-21.377Z"
              />
              <path
                fill="#131523"
                d="M772.1104,58.5912v18.8916h-4.4736c-13.2568,0-20.2168,5.7998-22.7021,20.0508v48.7188h-19.8857V60.2484h16.9023l.8291,15.7422c3.8115-11.9307,11.1025-17.3994,23.1992-17.3994h6.1309Z"
              />
            </g>
            <path
              fill="#4082ff"
              style={{ mixBlendMode: 'multiply' }}
              opacity={0.25}
              d="M119.7273,171.0285H38.4425c-4.2755,0-6.6897-2.2874-5.3925-5.109l20.6103-44.8276c1.2973-2.8216,5.8149-5.109,10.0904-5.109h81.2848c4.2755,0,6.6897,2.2874,5.3925,5.109l-20.6103,44.8276c-1.2973,2.8216-5.8149,5.109-10.0904,5.109Z"
            />
            <path
              fill="#4082ff"
              style={{ mixBlendMode: 'multiply' }}
              opacity={0.5}
              d="M150.7938,125.0139H21.1251c-4.2755,0-6.6897-2.2874-5.3925-5.109l25.672-55.8368c1.2973-2.8216,5.8149-5.109,10.0904-5.109H181.1636c4.2755,0,6.6897,2.2874,5.3925,5.109l-25.672,55.8368c-1.2973,2.8216-5.8149,5.109-10.0904,5.109Z"
            />
            <path
              fill="#4082ff"
              style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
              d="M183.7955,77.0639H5.7431c-4.2755,0-6.6897-2.2874-5.3925-5.109L31.0843,5.109c1.2973-2.8216,5.8149-5.109,10.0904-5.109H219.2271c4.2755,0,6.6897,2.2874,5.3925,5.109l-30.7336,66.8459c-1.2973,2.8216-5.8149,5.109-10.0904,5.109Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
