import {
  useState,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'
import { isFocusable } from 'typeguards'

export type UsePopoverReturnValue = {
  isOpen: boolean
  onClose: VoidFunction
  onOpen: VoidFunction
  ref: MutableRefObject<any>
  referenceEl: any
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setReferenceEl: Dispatch<any>
}

type usePopoverArgs = {
  focusRef?: MutableRefObject<any>
}
export const usePopover = (props?: usePopoverArgs): UsePopoverReturnValue => {
  const ref = useRef<HTMLElement>(null)
  const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const focusRef: HTMLElement = props?.focusRef?.current ?? referenceEl
  const onClose = useCallback(() => {
    if (isFocusable(focusRef)) {
      setTimeout(() => focusRef.focus({ preventScroll: true }), 0)
    }
    setIsOpen(false)
  }, [focusRef])

  const onOpen = useCallback(() => {
    if (isFocusable(focusRef)) {
      focusRef.focus({ preventScroll: false })
    }
    setIsOpen(true)
  }, [focusRef])

  return {
    isOpen,
    onClose,
    onOpen,
    ref,
    referenceEl,
    setIsOpen,
    setReferenceEl,
  }
}
