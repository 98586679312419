/** @jsxImportSource theme-ui */
import { sendGTMEvent } from '@next/third-parties/google'
import { LangCookies } from 'contexts'
import { maxExpiry } from 'datetime'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useCookies } from 'react-cookie'
import { Box, ChevronDownIcon, Grid, Menu, Option, usePopover } from 'ui'
import { GlobeIcon } from './GlobeIcon'

export const d = {
  langEng: 'tensor-website-common:languages.en',
  langJp: 'tensor-website-common:languages.jp',
}

export interface LanguageMenuProps {}
export const LanguageMenu: FC<LanguageMenuProps> = () => {
  const router = useRouter()
  const { setReferenceEl, ...popoverProps } = usePopover()
  const { lang } = useTranslation()
  const [_cookies, setCookie] = useCookies([LangCookies.NEXT_LOCALE])

  const selectLanguage = (targetLang: string) => {
    sendGTMEvent({ event: 'language_switcher', value: targetLang })
    setCookie(LangCookies.NEXT_LOCALE, targetLang, {
      path: '/',
      expires: maxExpiry,
    })
    router.replace(router.asPath, undefined, { locale: targetLang })
  }

  const langOptions: Option<string>[] = [
    { label: '日本語', value: 'ja', variant: 'default' },
    { label: 'English', value: 'en', variant: 'default' },
  ]

  return (
    <>
      <button
        ref={setReferenceEl}
        sx={{
          bg: 'transparent',
          border: 'none',
          cursor: 'pointer',
          display: ['none', null, 'block', 'none'],
        }}
        onClick={popoverProps.onOpen}
        data-cy="language-menu-toggle-button"
        type="button"
        data-testid="language-menu-toggle-button"
      >
        <Grid style={{ placeItems: 'center' }} gap={1} columns={['1rem max-content']}>
          <Box style={{ height: '1rem', mt: lang === 'ja' ? '1px' : '-1px' }}>
            <GlobeIcon />
          </Box>
          <ChevronDownIcon color="textGray" style={{ height: '10px' }} />
        </Grid>
      </button>
      <Menu
        {...popoverProps}
        onChange={selectLanguage}
        sorted={false}
        placement="bottom-end"
        options={langOptions}
        paperStyle={{
          borderRadius: '6px',
          px: 1,
          py: 1,
          bg: 'white',
          borderColor: 'steel3',
          ul: {
            display: 'grid',
            gap: 1,
            px: 0,
            py: 0,
          },
          li: {
            borderRadius: 2,
            p: 2,
            cursor: 'pointer',
            mx: 0,
          },
        }}
      />
    </>
  )
}
