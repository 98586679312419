/* eslint-disable sonarjs/cognitive-complexity */
/** @jsxImportSource theme-ui */
import {
  ComponentPropsWithoutRef,
  FC,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useId,
  useRef,
} from 'react'
import { noSelect, useTheme } from 'theme'
import { StyleObject, TensorUIBaseProps } from 'typ'
import { isDefined, isFocusable, isNotNil } from 'typeguards'
import { Checkbox } from '../../atoms'
import { CheckIcon } from '../../icons'
import { Flex } from '../../layout'
import { Option } from '../../types'

export type MenuItemProps = Omit<
  ComponentPropsWithoutRef<'li'>,
  'onKeyDown' | 'onClick' | 'style'
> &
  TensorUIBaseProps & {
    areMouseEffectsDisabled?: boolean
    disabled?: boolean
    index: number
    isMulti?: boolean
    showSelectionTick?: boolean
    onCheckboxClick?: (value: Option['value'], i: number) => void
    onClick: (value: Option['value']) => void
    onKeyDown: (e: KeyboardEvent<HTMLLIElement>, index: number) => void
    onMouseEnter?: (e?: MouseEvent) => void
    onMouseLeave?: (e?: MouseEvent) => void
    option: Option
    selected?: boolean
    variant?: 'default' | 'danger'
  }
// TODO: ADD CUSTOM OPTION STYLES

export const MenuItem: FC<PropsWithChildren<MenuItemProps>> = ({
  areMouseEffectsDisabled,
  showSelectionTick,
  option,
  onClick,
  onCheckboxClick,
  index,
  onKeyDown,
  selected,
  onMouseEnter,
  disabled,
  variant = 'default',
  style,
  isMulti,
  ...props
}) => {
  const itemRef = useRef<HTMLLIElement>(null)
  const id = useId()
  const { styles } = useTheme()
  const isSelectable = !isMulti || (isMulti && isNotNil(option.value) && option.value !== '')
  const checkboxInteractiveStyles: StyleObject = {
    'div>div': {
      visibility: 'visible',
    },
  }

  const getActiveStyles = (activeColorBg: string, isHover?: boolean) =>
    isHover && areMouseEffectsDisabled
      ? {}
      : {
          bg: disabled ? 'white' : activeColorBg,
          ...checkboxInteractiveStyles,
        }

  const defaultStyles: StyleObject = {
    color: disabled ? 'textGray' : 'text',
    ':hover': getActiveStyles('steel4', true),
    ':focus-visible': getActiveStyles('steel4'),
  }
  const dangerStyles: StyleObject = {
    color: disabled ? 'textGray' : selected ? 'white' : 'danger',
    ':hover': getActiveStyles('dangerTint1', true),
    ':focus-visible': getActiveStyles('dangerTint1'),
  }

  const variantStyles = variant === 'danger' ? dangerStyles : defaultStyles

  const tabIndex = isDefined(selected) ? (selected ? 0 : -1) : index > 0 ? -1 : 0
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (isSelectable && !disabled) onClick(option.value)
  }
  const handleCheckboxClick = (i: number) => {
    if (isSelectable && !disabled) onCheckboxClick?.(option.value, i)
  }
  const handleKeyDown = (e: KeyboardEvent<HTMLLIElement>, i: number) => {
    if (!disabled || (disabled && !['Enter', 'NumpadEnter'].includes(e.code))) onKeyDown(e, i)
  }

  const withCheckboxLiStyleModifiers = isMulti ? { pl: 2 } : {}

  const handleMouseMove = () => {
    const el = itemRef.current
    if (isFocusable(el)) {
      el.focus({ preventScroll: true })
    }
  }

  return (
    <li
      {...props}
      ref={itemRef}
      role="menuitem"
      aria-disabled={disabled}
      aria-label={option.label}
      aria-labelledby={option.label}
      tabIndex={tabIndex}
      onClick={handleClick}
      onKeyDown={(e) => handleKeyDown(e, index)}
      onMouseMove={handleMouseMove}
      onMouseEnter={onMouseEnter}
      sx={{
        ...noSelect,
        ...styles.menuItem,
        'div>div': {
          visibility: selected ? 'visible' : 'hidden',
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: disabled ? 'not-allowed !important' : 'default',
        minHeight: '2rem',
        ...variantStyles,
        ...withCheckboxLiStyleModifiers,
        ...style,
      }}
      data-testid={props['data-testid']}
      data-cy={props['data-cy'] ?? option.label}
    >
      <Flex style={{ alignItems: 'center', gap: 2, width: '100%' }}>
        {isMulti && isSelectable && (
          <Checkbox
            checked={Boolean(selected)}
            disabled={disabled}
            onChange={() => handleCheckboxClick(index)}
            hideLabel
            label={option.label}
            id={id}
            tabIndex={-1}
          />
        )}
        <span sx={{ mt: '1px', flexGrow: 1 }}>{option.label}</span>
        {!isMulti && selected && showSelectionTick && <CheckIcon color="text" />}
      </Flex>
    </li>
  )
}

MenuItem.displayName = 'MenuItem'
