/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { StyledProps } from 'typ'

export const TensorFullNameSmallLogo: FC<StyledProps> = ({ style }) => (
  <svg
    width="150"
    height="21"
    viewBox="0 0 150 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="tensor-full-name-small-logo"
    sx={style}
  >
    <path
      d="M40.375 5.79907H36.8201V16.4264H34.5494V5.79907H30.9946V3.77039H40.375V5.79907Z"
      fill="#131523"
    />
    <path
      d="M42.5714 12.5552C42.5714 14.1186 43.2041 14.8444 44.6372 14.8444C45.8097 14.8444 46.4426 14.3419 46.5356 13.5044H48.6759C48.5456 15.5702 47.1125 16.6497 44.6372 16.6497C41.8082 16.6497 40.3379 15.2352 40.3379 12.5365V10.6381C40.3379 7.97669 41.7897 6.54358 44.5256 6.54358C47.2615 6.54358 48.7318 7.99534 48.7318 10.6568V12.2574H42.5714V12.5552V12.5552ZM42.5714 10.6009H46.6101C46.6101 9.03754 45.9959 8.34895 44.6 8.34895C43.1855 8.34895 42.5714 9.03754 42.5714 10.6009Z"
      fill="#131523"
    />
    <path
      d="M59.006 10.0426V16.4264H56.7726V10.2473C56.7726 9.05618 56.3259 8.51644 55.4139 8.51644C54.4834 8.51644 53.7761 9.05619 53.2364 10.1542V16.4264H51.0029V6.76689H52.9013L52.9758 8.08834C53.7575 7.00885 54.7067 6.54358 55.9723 6.54358C57.9823 6.54358 59.006 7.73473 59.006 10.0426Z"
      fill="#131523"
    />
    <path
      d="M60.8857 13.523H63.0261C63.0261 14.4722 63.6217 14.9375 64.9431 14.9375C66.1157 14.9375 66.7113 14.5467 66.7113 13.8021C66.7113 13.0019 66.1157 12.6482 64.4778 12.4622C62.0211 12.1644 61.0905 11.3827 61.0905 9.59593C61.0905 7.60444 62.4492 6.54358 64.9246 6.54358C67.4 6.54358 68.7027 7.58578 68.7027 9.57728H66.5437C66.5437 8.68392 66.0226 8.25583 64.9059 8.25583C63.8451 8.25583 63.2867 8.66527 63.2867 9.35396C63.2867 10.117 63.8451 10.4148 65.427 10.6009C67.9396 10.8801 68.9075 11.7548 68.9075 13.5975C68.9075 15.626 67.5674 16.6497 64.9246 16.6497C62.1887 16.6497 60.8857 15.6075 60.8857 13.523V13.523Z"
      fill="#131523"
    />
    <path
      d="M70.564 12.4435V10.7498C70.564 7.95805 72.0157 6.54358 74.8446 6.54358C77.6736 6.54358 79.144 7.95805 79.144 10.7498V12.4435C79.144 15.2352 77.6737 16.6497 74.8446 16.6497C72.0156 16.6497 70.564 15.2352 70.564 12.4435ZM76.9106 12.3877V10.8056C76.9106 9.1492 76.2591 8.40478 74.8446 8.40478C73.4302 8.40478 72.7974 9.14919 72.7974 10.8056V12.3877C72.7974 14.0441 73.4302 14.7886 74.8446 14.7886C76.2591 14.7886 76.9106 14.0441 76.9106 12.3877Z"
      fill="#131523"
    />
    <path
      d="M86.7193 6.58078V8.70259H86.2168C84.7279 8.70259 83.9462 9.35399 83.6671 10.9546V16.4264H81.4336V6.76691H83.332L83.4251 8.53499C83.8532 7.195 84.6721 6.58078 86.0307 6.58078H86.7193H86.7193Z"
      fill="#131523"
    />
    <path
      d="M101.813 14.4349V16.4264H93.1401V3.77039H101.627V5.76188H95.4108V8.98172H100.696V10.9546H95.4108V14.4349H101.813Z"
      fill="#131523"
    />
    <path
      d="M111.975 10.0426V16.4264H109.742V10.2473C109.742 9.05618 109.295 8.51644 108.383 8.51644C107.453 8.51644 106.745 9.05619 106.206 10.1542V16.4264H103.972V6.76689H105.871L105.945 8.08834C106.727 7.00885 107.676 6.54358 108.942 6.54358C110.952 6.54358 111.975 7.73473 111.975 10.0426Z"
      fill="#131523"
    />
    <path
      d="M116.405 12.5552C116.405 14.1186 117.038 14.8444 118.471 14.8444C119.644 14.8444 120.277 14.3419 120.37 13.5044H122.51C122.38 15.5702 120.947 16.6497 118.471 16.6497C115.642 16.6497 114.172 15.2352 114.172 12.5365V10.6381C114.172 7.97669 115.624 6.54358 118.36 6.54358C121.095 6.54358 122.566 7.99534 122.566 10.6568V12.2574H116.405V12.5552V12.5552ZM116.405 10.6009H120.444C120.444 9.03754 119.83 8.34895 118.434 8.34895C117.019 8.34895 116.405 9.03754 116.405 10.6009Z"
      fill="#131523"
    />
    <path
      d="M130.122 6.58078V8.70259H129.62C128.131 8.70259 127.349 9.35399 127.07 10.9546V16.4264H124.836V6.76691H126.735L126.828 8.53499C127.256 7.195 128.075 6.58078 129.434 6.58078H130.122H130.122Z"
      fill="#131523"
    />
    <path
      d="M139.745 6.7669V16.1844C139.745 18.8087 138.33 20.037 135.464 20.037C132.952 20.037 131.574 19.0693 131.444 16.9475H133.528C133.621 17.8595 134.292 18.2318 135.427 18.2318C136.879 18.2318 137.511 17.6362 137.511 16.1845V14.863C136.785 15.7005 135.911 16.0728 134.757 16.0728C132.542 16.0728 131.258 14.6955 131.258 12.0713V10.5265C131.258 7.90225 132.542 6.54361 134.757 6.54361C136.041 6.54361 137.009 7.02753 137.772 8.06973L137.846 6.76693H139.745L139.745 6.7669ZM137.511 12.4435V10.1542C136.99 9.0189 136.209 8.40479 135.204 8.40479C134.105 8.40479 133.491 9.11202 133.491 10.5637V12.0526C133.491 13.523 134.105 14.2116 135.185 14.2116C136.209 14.2116 136.99 13.5975 137.511 12.4435Z"
      fill="#131523"
    />
    <path
      d="M150 6.76691L146.408 17.4128C145.794 19.274 145.124 19.8137 143.411 19.8137H142.22V18.0084H143.188C143.802 18.0084 144.081 17.8223 144.286 17.1523L144.621 16.1845L141.234 6.76691H143.598L145.757 13.3555L147.748 6.76691H150Z"
      fill="#131523"
    />
    <g style={{ mixBlendMode: 'multiply', opacity: '0.25' }}>
      <path
        d="M13.4474 19.209H4.31789C3.83769 19.209 3.56654 18.9521 3.71223 18.6352L6.02707 13.6004C6.17278 13.2835 6.68017 13.0266 7.16037 13.0266H16.2899C16.7701 13.0266 17.0412 13.2835 16.8955 13.6004L14.5807 18.6352C14.435 18.9521 13.9276 19.209 13.4474 19.209Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply', opacity: '0.5' }}>
      <path
        d="M16.9363 14.0409H2.37258C1.89238 14.0409 1.62123 13.784 1.76692 13.4671L4.65026 7.19579C4.79597 6.87889 5.30336 6.62198 5.78356 6.62198H20.3473C20.8275 6.62198 21.0986 6.87889 20.9529 7.19579L18.0696 13.4671C17.9239 13.784 17.4165 14.0409 16.9363 14.0409H16.9363Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M20.6429 8.65541H0.645038C0.164836 8.65541 -0.106314 8.3985 0.0393807 8.0816L3.49123 0.573816C3.63693 0.256909 4.14433 0 4.62453 0H24.6224C25.1026 0 25.3738 0.256909 25.2281 0.573816L21.7763 8.0816C21.6306 8.3985 21.1232 8.65541 20.643 8.65541H20.6429Z"
        fill="#4082FF"
      />
    </g>
  </svg>
)
