/** @jsxImportSource theme-ui */
import { forwardRef, ReactNode } from 'react'
import { TensorUIBaseProps } from 'typ'

export interface PaperProps extends TensorUIBaseProps {
  as?: React.ElementType
  children: ReactNode
  variant?: 'default' | 'danger'
}
export const Paper = forwardRef<HTMLDivElement, PaperProps>(
  ({ children, style, as: Component = 'div', variant = 'default', ...props }, ref) => (
    <Component
      ref={ref}
      sx={{
        borderRadius: 3,
        bg: 'white',
        border: '1px solid',
        borderColor: variant === 'danger' ? 'dangerTint3' : '#dddde3',
        ...style,
      }}
      data-testid={props['data-testid'] ?? 'paper'}
      data-cy={props['data-cy']}
    >
      {children}
    </Component>
  )
)

Paper.displayName = 'Paper'
