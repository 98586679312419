import { useMemo } from 'react'
import { gojuonSort } from 'array'
import { Option, OptionGroup } from '../types'

export interface UseOptionArgs {
  options: Option[]
  optionGroups: OptionGroup[]
  sorted?: boolean
  emptyOptionLabel?: string
}

export const useOptions = ({
  options,
  optionGroups,
  sorted,
  emptyOptionLabel,
}: UseOptionArgs): {
  sortedOptions: Option[]
  sortedOptionGroups: OptionGroup[]
  allOptions: Option[]
  getGroupOptionIndex: (
    optionIndex: number,
    groupIndex: number,
    groups: OptionGroup[]
  ) => number
} => {
  const sortedOptions = useMemo(() => {
    const emptyOption = emptyOptionLabel
      ? { label: emptyOptionLabel, value: '' }
      : null
    if (emptyOption) {
      if (!sorted) return [emptyOption, ...options]
      return [
        emptyOption,
        ...gojuonSort(options, null, (option) => option.label),
      ]
    }
    if (!sorted) return options
    return gojuonSort(options, null, (option) => option.label)
  }, [options, sorted, emptyOptionLabel])

  const sortedOptionGroups = useMemo(() => {
    if (!sorted) return optionGroups
    return optionGroups.map(({ options: opts, ...others }) => ({
      ...others,
      options: gojuonSort(opts, null, (option) => option.label),
    }))
  }, [optionGroups, sorted])

  const allOptions = useMemo(() => {
    const groupedOptions: Option[] = Array.from(sortedOptions)
    sortedOptionGroups.forEach((group) => {
      groupedOptions.push(...group.options)
    })
    return groupedOptions
  }, [sortedOptions, sortedOptionGroups])

  const getGroupOptionIndex = (
    optionIndex: number,
    groupIndex: number,
    groups: OptionGroup[]
  ) =>
    allOptions.findIndex(
      (option) => groups[groupIndex].options[optionIndex].value === option.value
    )

  return { sortedOptions, sortedOptionGroups, allOptions, getGroupOptionIndex }
}
