/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { FC, ReactNode } from 'react'
import { TensorUIBaseProps } from 'typ'
import { NextLink } from '../next-link'
import { BrandBlockSvg } from './BrandBlockSvg'
import { Box } from '../../layout'

export interface BrandBlockProps extends TensorUIBaseProps {
  href?: string
  brandHeight?: number
  Logo?: ReactNode
}
export const BrandBlock: FC<React.PropsWithChildren<BrandBlockProps>> = ({
  href = '/',
  brandHeight,
  style,
  Logo,
  ...props
}) => (
  <NextLink
    aria-label="Home"
    href={href}
    passHref
    style={{
      outline: 'none',
      '&:focus-visible': {
        boxShadow: 'focusRing',
        borderRadius: 2,
      },
    }}
    data-testid={props['data-testid'] ?? 'brand-block'}
    data-cy={props['data-cy']}
  >
    <Box
      style={{
        my: 3,
        justifySelf: 'flex-start',
        px: 5,
        userSelect: 'none',
        height: 'max-content',
        ...style,
      }}
    >
      {Logo ?? <BrandBlockSvg size={brandHeight} />}
    </Box>
  </NextLink>
)
