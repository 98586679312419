/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { SvgIconProps } from '../types'

export const ArrowRightIcon: FC<SvgIconProps> = ({
  size = 16,
  color = '#0c5cf3',
  style = {},
  ...props
}) => (
  <svg
    sx={{ height: `${size}px`, ...style }}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="arrow-right-icon"
    {...props}
  >
    <path
      d="M1.99976 7.25C1.58554 7.25 1.24976 7.58579 1.24976 8C1.24976 8.41421 1.58554 8.75 1.99976 8.75L1.99976 7.25ZM14.2144 7.25L1.99976 7.25L1.99976 8.75L14.2144 8.75L14.2144 7.25Z"
      sx={{ fill: color, stroke: 'transparent' }}
    />
    <path
      d="M9.5 13L14.1464 8.35355C14.3417 8.15829 14.3417 7.84171 14.1464 7.64645L9.5 3"
      sx={{ fill: 'transparent', stroke: color }}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
