/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { StyledProps } from 'typ'

export const TensorSmallLogo: FC<StyledProps> = ({ style }) => (
  <svg
    width="82"
    height="19"
    viewBox="0 0 82 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={style}
  >
    <path
      d="M37.8334 5.93405H34.5022V15.8925H32.3745V5.93405H29.0435V4.03305H37.8334V5.93405Z"
      fill="#131523"
    />
    <path
      d="M39.8917 12.2649C39.8917 13.7299 40.4847 14.4101 41.8276 14.4101C42.9263 14.4101 43.5193 13.9392 43.6065 13.1544H45.6121C45.49 15.0902 44.1471 16.1018 41.8276 16.1018C39.1766 16.1018 37.7988 14.7763 37.7988 12.2474V10.4686C37.7988 7.97463 39.1592 6.63171 41.7229 6.63171C44.2866 6.63171 45.6644 7.9921 45.6644 10.486V11.9859H39.8917V12.2649ZM39.8917 10.4337H43.6762C43.6762 8.9687 43.1007 8.32345 41.7927 8.32345C40.4672 8.32345 39.8917 8.9687 39.8917 10.4337Z"
      fill="#131523"
    />
    <path
      d="M55.2923 9.91046V15.8925H53.1994V10.1024C53.1994 8.98617 52.7809 8.4804 51.9263 8.4804C51.0543 8.4804 50.3916 8.98618 49.8858 10.0151V15.8925H47.793V6.84097H49.5719L49.6416 8.07925C50.3742 7.0677 51.2636 6.63171 52.4496 6.63171C54.3331 6.63171 55.2923 7.7479 55.2923 9.91046Z"
      fill="#131523"
    />
    <path
      d="M57.0537 13.1718H59.0593C59.0593 14.0613 59.6174 14.4973 60.8557 14.4973C61.9545 14.4973 62.5126 14.1311 62.5126 13.4334C62.5126 12.6835 61.9545 12.3521 60.4197 12.1778C58.1176 11.8987 57.2456 11.1662 57.2456 9.49195C57.2456 7.6258 58.5187 6.63171 60.8383 6.63171C63.1579 6.63171 64.3787 7.60832 64.3787 9.47447H62.3556C62.3556 8.63734 61.8673 8.23619 60.8209 8.23619C59.8268 8.23619 59.3035 8.61987 59.3035 9.26521C59.3035 9.98024 59.8268 10.2593 61.3092 10.4337C63.6636 10.6953 64.5705 11.5149 64.5705 13.2416C64.5705 15.1425 63.3148 16.1018 60.8383 16.1018C58.2746 16.1018 57.0537 15.1252 57.0537 13.1718Z"
      fill="#131523"
    />
    <path
      d="M66.1226 12.1603V10.5732C66.1226 7.95716 67.4829 6.63171 70.1338 6.63171C72.7847 6.63171 74.1625 7.95716 74.1625 10.5732V12.1603C74.1625 14.7763 72.7848 16.1018 70.1338 16.1018C67.4828 16.1018 66.1226 14.7763 66.1226 12.1603ZM72.0698 12.108V10.6255C72.0698 9.07334 71.4592 8.37577 70.1338 8.37577C68.8084 8.37577 68.2155 9.07333 68.2155 10.6255V12.108C68.2155 13.6601 68.8084 14.3578 70.1338 14.3578C71.4592 14.3578 72.0698 13.6601 72.0698 12.108Z"
      fill="#131523"
    />
    <path
      d="M81.2611 6.66655V8.65481H80.7903C79.3951 8.65481 78.6626 9.26521 78.401 10.7651V15.8925H76.3081V6.84096H78.087L78.1743 8.49776C78.5754 7.24211 79.3427 6.66655 80.6159 6.66655H81.2611Z"
      fill="#131523"
    />
    <g style={{ mixBlendMode: 'multiply', opacity: '0.25' }}>
      <path
        d="M12.6007 18.5H4.04585C3.59587 18.5 3.34178 18.2593 3.47831 17.9623L5.64745 13.2444C5.78399 12.9474 6.25944 12.7067 6.70942 12.7067H15.2643C15.7143 12.7067 15.9683 12.9474 15.8318 13.2444L13.6627 17.9623C13.5261 18.2593 13.0507 18.5 12.6007 18.5Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply', opacity: '0.5' }}>
      <path
        d="M15.8706 13.6572H2.22358C1.7736 13.6572 1.51952 13.4164 1.65604 13.1195L4.35791 7.24288C4.49444 6.94592 4.9699 6.70518 5.41988 6.70518H19.0669C19.5169 6.70518 19.771 6.94592 19.6345 7.24288L16.9326 13.1195C16.7961 13.4164 16.3206 13.6572 15.8706 13.6572Z"
        fill="#4082FF"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M19.3437 8.61063H0.604439C0.154461 8.61063 -0.0996225 8.36989 0.036902 8.07293L3.27149 1.0377C3.40802 0.740739 3.88348 0.5 4.33346 0.5H23.0727C23.5227 0.5 23.7767 0.740739 23.6402 1.0377L20.4056 8.07293C20.2691 8.36989 19.7936 8.61063 19.3437 8.61063Z"
        fill="#4082FF"
      />
    </g>
  </svg>
)
