export const GlobeIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 1.5C10.2239 1.5 11.8772 2.18482 13.0962 3.40381C14.3152 4.62279 15 6.27609 15 8C15 9.72391 14.3152 11.3772 13.0962 12.5962C11.8772 13.8152 10.2239 14.5 8.5 14.5C6.77609 14.5 5.12279 13.8152 3.90381 12.5962C2.68482 11.3772 2 9.72391 2 8C2 6.27609 2.68482 4.62279 3.90381 3.40381C5.12279 2.18482 6.77609 1.5 8.5 1.5ZM13.394 5.5C12.7526 4.25301 11.6613 3.29641 10.341 2.824C10.785 3.664 11.106 4.564 11.294 5.5H13.394ZM13.976 8.495C13.992 8.33049 14 8.16529 14 8C13.999 7.49253 13.9273 6.98768 13.787 6.5H11.445C11.477 6.831 11.5 7.164 11.5 7.5C11.4979 8.17185 11.4289 8.84183 11.294 9.5H13.787C13.882 9.171 13.945 8.835 13.977 8.495H13.976ZM10.441 8.495L10.447 8.444C10.4811 8.13047 10.4988 7.81538 10.5 7.5C10.4933 7.16551 10.4679 6.83166 10.424 6.5H6.576C6.53172 6.83162 6.50635 7.16549 6.5 7.5C6.50244 8.17329 6.5806 8.84418 6.733 9.5H10.267C10.344 9.168 10.402 8.833 10.441 8.495ZM10.249 5.5C10.0134 4.44235 9.58821 3.43611 8.994 2.53C8.83 2.516 8.666 2.5 8.5 2.5C8.39583 2.50051 8.29174 2.50551 8.188 2.515L8.006 2.53L8 2.54C7.40893 3.44346 6.98581 4.44621 6.751 5.5H10.249ZM5.706 5.5C5.89781 4.56465 6.22314 3.66174 6.672 2.819C5.34598 3.2898 4.24946 4.24863 3.606 5.5H5.706ZM3.213 6.5C3.07289 6.98773 3.00121 7.49255 3 8C3.00121 8.50745 3.07289 9.01227 3.213 9.5H5.706C5.57043 8.84193 5.50141 8.17189 5.5 7.5C5.5 7.164 5.523 6.831 5.555 6.5H3.213ZM5.967 10.5H3.607C3.98501 11.2384 4.52472 11.882 5.18594 12.3829C5.84716 12.8838 6.61285 13.229 7.426 13.393C6.78599 12.5142 6.29322 11.5371 5.967 10.5ZM8.5 13.144C9.13944 12.3531 9.63928 11.459 9.978 10.5H7.022C7.36074 11.459 7.86057 12.3531 8.5 13.144ZM11.033 10.5C10.7068 11.5371 10.214 12.5142 9.574 13.393C10.3871 13.2289 11.1527 12.8836 11.8139 12.3827C12.4751 11.8818 13.0149 11.2383 13.393 10.5H11.033Z"
      fill="#131523"
    />
  </svg>
)
