/** @jsxImportSource theme-ui */
import { sendGTMEvent } from '@next/third-parties/google'
import { LangCookies } from 'contexts'
import { maxExpiry } from 'datetime'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useCookies } from 'react-cookie'
import { StyledProps } from 'typ'
import { Box, Flex, Grid, Text } from 'ui'
import { GlobeIcon } from './GlobeIcon'

const locales = ['en', 'ja']
export interface LanguageSwitchProps extends StyledProps {}
export const LanguageSwitch: FC<LanguageSwitchProps> = ({ style }) => {
  const router = useRouter()
  const { lang } = useTranslation()
  const [_cookies, setCookie] = useCookies([LangCookies.NEXT_LOCALE])

  const selectLanguage = () => {
    const targetLang = locales.filter((lng) => lng !== lang).join()
    sendGTMEvent({ event: 'language_switcher', value: targetLang })
    setCookie(LangCookies.NEXT_LOCALE, targetLang, {
      path: '/',
      expires: maxExpiry,
    })
    router.replace(router.asPath, undefined, { locale: targetLang })
  }
  return (
    <Grid data-testid="language-switch" style={{ placeItems: 'center', ...style }}>
      <button
        sx={{
          borderRadius: 2,
          bg: 'transparent',
          border: 'none',
          cursor: 'pointer',
          ':focus-visible': {
            outline: 'none',
            boxShadow: 'focusRing',
          },
        }}
        onClick={selectLanguage}
        data-cy="language-switch-button"
        data-lang={lang}
      >
        <Flex style={{ alignItems: 'center', gap: 1, height: '22px' }}>
          <Grid style={{ placeItems: 'center' }}>
            <Box style={{ height: '1rem', mt: lang === 'ja' ? '1px' : '-1px' }}>
              <GlobeIcon />
            </Box>
          </Grid>
          <Text
            variant="body3"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, max-content)',
              placeItems: 'center',
              gap: 1,
            }}
          >
            <Text color={lang === 'ja' ? 'text' : 'textGray'}>日本語</Text>
            <Text style={lang === 'ja' ? { mb: '2px' } : {}}>|</Text>
            <Text color={lang === 'en' ? 'text' : 'textGray'}>EN</Text>
          </Text>
        </Flex>
      </button>
    </Grid>
  )
}
