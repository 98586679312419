/** @jsxImportSource theme-ui */
import { FC } from 'react'
import { SvgIconProps } from '../types'

export const CheckIcon: FC<SvgIconProps> = ({
  size = 16,
  color = '#0c5cf3',
  style = {},
  ...props
}) => (
  <svg
    sx={{ height: `${size}px`, ...style }}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="check-icon"
    {...props}
  >
    <path
      d="M2.5 7.5L5.78888 11.2587C5.90316 11.3893 6.10408 11.396 6.22679 11.2733L9.75004 7.75L13.5 4"
      sx={{ stroke: color }}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
