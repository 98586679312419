/** @jsxImportSource theme-ui */
import { Placement, VirtualElement } from '@popperjs/core'
import { ReactNode, forwardRef, useId, useState } from 'react'
import { usePopper } from 'react-popper'
import { StyleObject, TensorUIBaseProps } from 'typ'
import { AnimatedWrapper } from './AnimatedWrapper'
import { StaticWrapper } from './StaticWrapper'

type BaseProps = {
  offset?: [number, number]
  placement?: Placement
  children: ReactNode
  referenceEl: Element | VirtualElement
  id?: string
  strategy?: 'fixed' | undefined
  showArrow?: boolean
  arrowStyles?: StyleObject
  arrowSize?: number
  animated?: boolean
  isOpen?: boolean
  transformOrigin?: string
}

export type PopoverWrapperProps = BaseProps & TensorUIBaseProps

export const PopoverWrapper = forwardRef<HTMLUListElement, PopoverWrapperProps>(
  (
    {
      animated,
      arrowSize = 12,
      arrowStyles = {},
      children,
      transformOrigin,
      id,
      isOpen,
      offset,
      placement,
      referenceEl,
      showArrow = false,
      strategy,
      style,
      ...props
    },
    _ref
  ) => {
    const autoId = useId().replace(/[^a-z0-9]/gi, '')
    const [popperElement, setPopperElement] = useState(null)
    const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)
    const { styles, attributes } = usePopper(referenceEl, popperElement, {
      placement,
      modifiers: [
        { name: 'offset', options: { offset } },
        {
          name: 'arrow',
          options: {
            element: arrowRef,
            padding: 8,
          },
        },
      ],
      strategy,
    })

    const baseProps = {
      'data-testid': props['data-testid'],
      'data-cy': props['data-cy'],
      id: `popover-wrapper-${id ?? autoId}`,
      popperAttributes: attributes.popper,
      popperStyles: styles.popper,
      style,
      setPopperElement,
    }

    return animated ? (
      <AnimatedWrapper {...baseProps} isOpen={isOpen} transformOrigin={transformOrigin}>
        {children}
      </AnimatedWrapper>
    ) : (
      <StaticWrapper
        {...baseProps}
        showArrow={showArrow}
        arrowStyles={{ ...styles.arrow, ...arrowStyles }}
        ref={setArrowRef}
        arrowSize={arrowSize}
      >
        {children}
      </StaticWrapper>
    )
  }
)

PopoverWrapper.displayName = 'PopoverWrapper'
