/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  Container as ThemeUIContainer,
  ContainerProps as ThemeUIContainerProps,
} from 'theme-ui'
import { TensorUIBaseProps } from 'typ'

export type ContainerProps = Omit<ThemeUIContainerProps, 'sx' | 'style'> &
  TensorUIBaseProps

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ style, ...props }, ref) => (
    <ThemeUIContainer {...props} sx={style} ref={ref} />
  )
)

Container.displayName = 'Container'
