/** @jsxImportSource theme-ui */
import { useViewport } from '@/contexts/ViewportContext'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import { FC, ReactElement, ReactNode, cloneElement } from 'react'
import { roundedScrollbars, scrollbars } from 'theme'
import { Box, Button, ButtonProps, Close, Grid, Modal, Paper, useModal } from 'ui'

const InlineWidget = dynamic(() => import('react-calendly').then((mod) => mod.InlineWidget))

export interface DemoModalToggleProps {
  buttonText?: ReactNode
  CustomToggle?: ReactElement<ButtonProps, 'button'>
  customToggleProps?: ButtonProps
  size?: ButtonProps['size']
}

const CALENDLY_URL_EN = 'https://calendly.com/asako-saito-et-tensorenergy/30min'
const CALENDLY_URL_JP = 'https://calendly.com/asako-saito-et-tensorenergy/tensor-energy-30'

const d = {
  defaultBtnText: 'tensor-website-common:cta.book-demo',
}
export const DemoModalToggle: FC<DemoModalToggleProps> = ({
  CustomToggle,
  buttonText,
  size = 'md',
  customToggleProps,
}) => {
  const { lang, t } = useTranslation()
  const { isOpen, openModal, closeModal, anchorRef } = useModal()
  const { width, height } = useViewport()
  const calendlyUrl = lang === 'en' ? CALENDLY_URL_EN : CALENDLY_URL_JP
  // THESE BREAKPOINTS ARE BASED ON CALENDLY INTERNAL SETTINGS
  const isLg = width > 1110
  const isMd = width > 800
  const isSm = width > 600
  const isMobile = width <= 600
  const isTall = height > 750
  const widgetWidth = isLg ? '1110px' : isMd ? '760px' : isSm ? '560px' : '100vw'
  const widgetHeight = isLg ? '700px' : isMd ? '1080px' : '1060px'
  const paperHeight = isTall ? '708px' : '95vh'

  const scrollbarStyles = isMobile ? scrollbars : roundedScrollbars
  return (
    <>
      {CustomToggle ? (
        cloneElement(
          CustomToggle,
          {
            onClick: () => openModal(),
            ref: anchorRef,
            ...customToggleProps,
          },
          <>{buttonText}</>
        )
      ) : (
        <Button ref={anchorRef} size={size} onClick={() => openModal()}>
          {buttonText ?? t(d.defaultBtnText)}
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        wrapperStyles={{
          padding: [0, 3],
        }}
      >
        <Box
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        >
          <Paper
            style={{
              ...scrollbarStyles,
              height: paperHeight,
              overflowY: 'auto',
              overflowX: 'hidden',
              borderRadius: [0, 3],
              borderColor: 'primaryTint2',
              background: 'white',
            }}
          >
            <Grid
              style={{
                height: '100%',
                width: '100%',
                placeItems: 'center',
              }}
            >
              <Box
                style={{
                  height: '100%',
                  width: '100%',
                  zIndex: 1030,
                }}
              >
                <InlineWidget
                  url={calendlyUrl}
                  styles={{ width: widgetWidth, height: widgetHeight }}
                />
              </Box>
            </Grid>
            <Close
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: 2,
                right: 2,
                zIndex: 1031,
              }}
            />
          </Paper>
        </Box>
      </Modal>
    </>
  )
}
