/** @jsxImportSource theme-ui */
import { forwardRef, PropsWithChildren, ChangeEvent } from 'react'
import { Wrapper, CheckboxLabel, getBaseInputStyles, CheckSvg } from './shared'
import { CoreCheckboxProps, CoreLabelProps } from './types'
import { Flex } from '../../layout'

export type CheckboxProps = CoreCheckboxProps & CoreLabelProps
export const Checkbox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<CheckboxProps>
>(
  (
    {
      checked = false,
      disabled,
      hideLabel,
      id,
      label,
      labelStyle,
      onChange,
      onFocus,
      style,
      name,
      ...props
    },
    ref
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      onChange(e)
    }
    const baseStyles = getBaseInputStyles('sm', disabled)

    return (
      <Flex style={{ placeItems: 'center', maxWidth: 'max-content' }} ref={ref}>
        <Wrapper
          checked={checked}
          size="sm"
          disabled={disabled}
          style={style}
          Toggle={checked ? <CheckSvg disabled={disabled} /> : null}
        >
          <input
            type="checkbox"
            aria-label={label ?? id}
            id={id}
            name={name}
            checked={checked}
            disabled={disabled}
            onFocus={onFocus}
            sx={baseStyles}
            onChange={handleChange}
            // data-testid={props['data-testid']}
            // data-cy={props['data-cy']}
            // onKeyDown={onKeyDown}
            {...props}
          />
        </Wrapper>
        <CheckboxLabel
          id={id}
          label={label}
          hideLabel={hideLabel}
          style={labelStyle}
        />
      </Flex>
    )
  }
)

Checkbox.displayName = 'Checkbox'
