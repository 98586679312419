/** @jsxImportSource theme-ui */
import { FC, ComponentPropsWithRef, PropsWithChildren } from 'react'
import { TensorUIBaseProps } from 'typ'
import { Button, SizedButtonProps } from './Button'

export interface AnchorButtonProps extends PropsWithChildren {
  anchorProps: Omit<ComponentPropsWithRef<'a'>, 'style'> & TensorUIBaseProps
  buttonProps?: Omit<SizedButtonProps, 'as'>
  // This is used when we need to clone an element and pass it an extra/modified onClick handler
  onClick?: VoidFunction
}
export const AnchorButton: FC<AnchorButtonProps> = ({
  anchorProps: { style: anchorStyle, ...anchorProps },
  buttonProps,
  children,
  onClick,
}) => (
  <a
    sx={{
      display: 'block',
      width: 'max-content',
      color: 'inherit',
      textDecoration: 'none',
      borderRadius: 2,
      outline: 'none',
      ':focus-visible': {
        boxShadow: 'focusRing',
      },
      ...anchorStyle,
    }}
    {...anchorProps}
  >
    <Button as="div" {...buttonProps} onClick={buttonProps?.onClick ?? onClick}>
      {children}
    </Button>
  </a>
)
