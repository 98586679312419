import { unique } from 'array'
import { useState, useMemo } from 'react'
import { Maybe } from 'typ'
import { isFocusable } from 'typeguards'

type FocusableElement = { focus: VoidFunction }
type UseModalOptions = {
  preventFocusOnClose?: boolean
}

type CallbackRef<T extends FocusableElement> = (ref: T | null) => T | null
export function useModal<T extends string, Y extends FocusableElement = FocusableElement>(
  targets: readonly T[] | ['default'] = ['default'],
  options: UseModalOptions = {}
): {
  openModal: (target?: T) => void
  closeModal: VoidFunction
  isOpen: boolean
  //Relax the type of anchorRef to any, because update broke it and this is going to be
  anchorRef: CallbackRef<any>
  anchors: Record<string, CallbackRef<any>>
  activeModal: string | null
  activeModalImperativeRef: Maybe<Y>
  modalsMap: Map<string, Y>
} {
  const targetsList = useMemo(() => [...unique(targets as T[])] as const, [targets])
  type ActiveModal = (typeof targetsList)[number] | 'default'
  const map = useMemo(() => new Map<string, Y>(), [])

  const [isOpen, setIsOpen] = useState(false)
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null)

  const closeModal = () => {
    setIsOpen(false)
    setTimeout(() => {
      const currentRef = map.get(activeModal as ActiveModal)
      if (!options.preventFocusOnClose && isFocusable(currentRef)) {
        currentRef.focus()
      }

      setActiveModal(null)
    }, 10)
  }
  const openModal = (target: ActiveModal = 'default') => {
    setActiveModal(target)
    setIsOpen(true)
  }

  const refSetters = useMemo(() => {
    const setters = {} as Record<ActiveModal, CallbackRef<Y>>
    targetsList.forEach((target) => {
      setters[target] = (ref: Y | null) => {
        if (ref) {
          map.set(target, ref)
          return ref
        }
        return null
      }
    })
    return setters
  }, [targetsList, map])

  return {
    openModal,
    closeModal,
    isOpen,
    anchorRef: refSetters.default,
    anchors: refSetters,
    activeModal,
    activeModalImperativeRef: map.get(activeModal as ActiveModal),
    modalsMap: map,
  }
}
