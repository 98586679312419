/** @jsxImportSource theme-ui */
import { LanguageMenu } from '@/components/language-switch/LanguageMenu'
import { LanguageSwitch } from '@/components/language-switch/LanguageSwitch'
import { TensorFullNameLogo } from '@/components/logos/TensorFullNameLogo'
import { useViewport } from '@/contexts/ViewportContext'
import { PROD_BASE_URL } from 'links'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useMemo } from 'react'
import { COMPANY_URL, CONTACT_URL, FEATURES_URL, PRICING_URL } from 'src/assets/links'
import {
  WEBSITE_MOBILE_TOP_NAV_HEIGHT,
  WEBSITE_TOP_NAV_HEIGHT,
  cssValueToNumber,
  useTheme,
} from 'theme'
import { AnchorButton, Box, BrandBlock, Flex, Grid, NextLink } from 'ui'
import { DemoModalToggle } from '../DemoModalToggle'
import { TensorSmallLogo } from '../logos/TensorSmallLogo'
import { HamburgerMenuToggle } from './HamburgerMenuToggle'
import { getActivePath } from './get-active-path'

export type NavLinkProps = PropsWithChildren & {
  href: string
  activePath: string
}

const DynamicLogo = () => (
  <Box>
    <TensorFullNameLogo style={{ display: ['none', null, 'block'] }} />
    <TensorSmallLogo style={{ display: ['block', null, 'none'] }} />
  </Box>
)
const NavLink: FC<NavLinkProps> = ({ href, children, activePath }) => {
  const isActive = activePath === href
  return (
    <Grid style={{ width: '100%', placeItems: 'center' }}>
      <NextLink
        data-selected={isActive}
        href={href}
        style={{
          display: 'grid',
          height: '33px',
          width: 'max-content',
          padding: '6px 12px',
          color: isActive ? 'text' : 'textGray',
          fontWeight: isActive ? 'medium' : 'normal',
          textDecoration: 'none',
          fontSize: 2,
          outline: 'none',
          bg: 'transparent',
          transition: 'background .1s',
          borderRadius: 2,
          ':hover': {
            bg: 'rgba(242,244,250, .5)',
            color: 'text',
          },
          ':focus-visible': {
            boxShadow: 'focusRing',
          },
        }}
      >
        {children}
      </NextLink>
    </Grid>
  )
}

export const d = {
  features: 'tensor-website-common:nav.features',
  pricing: 'tensor-website-common:nav.pricing',
  company: 'tensor-website-common:nav.company',
  contact: 'tensor-website-common:nav.contact',
  login: 'tensor-website-common:nav.login',
  getStarted: 'tensor-website-common:nav.get-started',
}
export interface TopNavBarProps {}
export const TopNavBar: FC<TopNavBarProps> = () => {
  const router = useRouter()
  const activePath = useMemo(() => getActivePath(router.pathname), [router])
  const { width } = useViewport()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = width < cssValueToNumber(theme.breakpoints[1])
  const showLangMenu = !isMobile && width < cssValueToNumber(theme.breakpoints[2])
  const showSideLangSwitch = width > 1530
  const showStandardLangSwitch = !showLangMenu && !showSideLangSwitch
  const links = useMemo(
    () => [
      { href: FEATURES_URL, label: t(d.features) },
      { href: PRICING_URL, label: t(d.pricing) },
      { href: COMPANY_URL, label: t(d.company) },
      { href: CONTACT_URL, label: t(d.contact) },
    ],
    [t]
  )

  return (
    <Box
      style={{
        position: 'sticky',
        top: 0,
        bg: 'transparent',
        zIndex: 'nav',
        pl: [4, null, null, null, 0],
        pr: [0],
        backdropFilter: 'blur(15px)',
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderBottom: '1px solid',
        borderColor: 'steel3',
        maxWidth: '100vw',
        '@media screen and (max-width: 320px)': {
          pl: 3,
        },
        '@media screen and (max-width: 280px)': {
          pl: 2,
        },
      }}
    >
      <Box
        style={{
          px: 0,
          py: 0,
          height: [WEBSITE_MOBILE_TOP_NAV_HEIGHT, null, WEBSITE_TOP_NAV_HEIGHT],
          minHeight: [WEBSITE_MOBILE_TOP_NAV_HEIGHT, null, WEBSITE_TOP_NAV_HEIGHT],
          maxWidth: '1200px',
          mx: 'auto',
        }}
      >
        {showSideLangSwitch && (
          <LanguageSwitch
            style={{
              display: ['none', null, null, null, 'grid'],
              position: 'absolute',
              top: 0,
              right: '32px',
              bottom: 0,
              placeItems: 'center',
            }}
          />
        )}

        <Grid
          as="nav"
          style={{
            justifyContent: ['space-between', null, null, 'center'],
            alignItems: 'center',
            height: '100%',
            pr: [0, null, 5, null, 0],
          }}
          columns={['1fr max-content', null, 'max-content 1fr max-content']}
        >
          <BrandBlock
            style={{ py: 0, my: 0, px: 0, height: 'max-content' }}
            Logo={<DynamicLogo />}
          />
          <Grid
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: ['none', null, 'grid'],
            }}
            columns={[`repeat(${links.length}, max-content)`]}
            gap={[0, 3, null, 5, 7]}
          >
            {links.map(({ href, label }) => (
              <NavLink key={href} href={href} activePath={activePath}>
                {label}
              </NavLink>
            ))}
          </Grid>
          <Flex
            style={{
              alignItems: 'center',
              gap: [3, 2, null, 3, 5],
              '@media screen and (max-width: 330px)': {
                gap: 2,
              },
            }}
          >
            {!isMobile && (
              <AnchorButton
                anchorProps={{
                  'data-testid': 'login-link',
                  href: PROD_BASE_URL,
                  target: '_blank',
                  rel: 'noreferrer',
                }}
                buttonProps={{
                  variant: 'tertiary',
                }}
              >
                {t(d.login)}
              </AnchorButton>
            )}
            <DemoModalToggle size={isMobile ? 'sm' : 'md'} buttonText={t(d.getStarted)} />

            {showStandardLangSwitch && (
              <LanguageSwitch style={{ display: ['none', null, null, 'grid', null, 'none'] }} />
            )}
            {showLangMenu && <LanguageMenu />}
            {isMobile && <HamburgerMenuToggle />}
          </Flex>
        </Grid>
      </Box>
    </Box>
  )
}
