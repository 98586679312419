/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Popover, PopoverProps } from '../../atoms'
import { MenuCore, MenuCoreProps } from './MenuCore'

export type MenuProps = Pick<
  PopoverProps,
  | 'animated'
  | 'autoFocus'
  | 'id'
  | 'isOpen'
  | 'offset'
  | 'onClose'
  | 'placement'
  | 'referenceEl'
> &
  MenuCoreProps

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ autoFocus, animated, isOpen, referenceEl, id, ...props }, ref) => (
    <Popover
      id={`${id ?? 'menu'}-popover`}
      animated={animated}
      isOpen={isOpen}
      autoFocus={autoFocus}
      onClose={props.onClose}
      referenceEl={referenceEl}
      style={props.style}
      offset={props.offset}
      placement={props.placement}
    >
      <MenuCore {...props} ref={ref} />
    </Popover>
  )
)

Menu.displayName = 'Menu'
